import styled from 'styled-components';
import { Layout } from 'antd';

/**
 * Whole layout wrapper
 */
const AuthLayout = styled(Layout)`
  //gap: 10px;
`;

/**
 * Content wrapper
 */
const AuthContentLayout = styled(Layout)`
  width: 100vw;
  height: 100vh;
  background-color: white;
`;

/**
 * Header
 */
const AuthLayoutHeader = styled(Layout.Header)`
  align-items: center;
  // background-image: linear-gradient(0deg, #ddf0f0, #dde0f0);
  // box-shadow: 0 0 4px rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #dddddd !important;
  height: 48px;
  color: black !important;
  display: flex;
  padding: 0 20px 0 20px;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;

  .anticon {
    font-size: 20px;
    color: #666666;
  }

  .tools {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .user_info_wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
    }

    > * {
      margin: 0 10px;
    }
  }
`;

/**
 * Sidebar
 */
const AuthLayoutSider = styled(Layout.Sider)`
  background-color: var(--primary-color) !important;
  overflow-x: hidden;
  width: 100% !important;
  // user name and avatar
  .name-avatar-container {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 15px;
    color: white;
  }

  // logout and setting
  .tool-container {
    margin-bottom: 25px;
    color: white;
  }

  // menu style
  .ant-menu {
    background-color: var(--primary-color) !important;
    border-right: none !important;
    color: white;
    width: 100% !important;
  }

  .ant-menu-item {
    // border-radius: 15px;
    width: 100% !important;

    &:hover {
      color: white !important;
      font-weight: bold;
      background-color: var(--primary-color-light) !important;
    }
  }

  .ant-menu-item::after {
    border: none !important;
  }

  .ant-menu-item-selected {
    color: white;
    background-color: var(--primary-color-light) !important;
  }
`;

const SIDEBAR_MARGIN_HORZ = '24px';
const SIDEBAR_MARGIN_VERT = '40px';

const AuthLayoutCornerHeader = styled.div`
  color: white;
  // padding-left: ${SIDEBAR_MARGIN_HORZ};
  padding-top: ${SIDEBAR_MARGIN_VERT};
  padding-bottom: ${SIDEBAR_MARGIN_VERT};

  .logo {
    width: 50%;
  }
`;

const AuthLayoutSideFooter = styled.div`
  position: absolute;
  color: white;
  left: ${SIDEBAR_MARGIN_HORZ};
  bottom: ${SIDEBAR_MARGIN_VERT};
  line-height: 18px;
  font-size: 14px;

  > div {
    margin-top: 8px;
  }

  .logo {
    margin-right: ${SIDEBAR_MARGIN_HORZ};
    width: 50%;
  }
`;

/**
 * Content wrapper
 */
const AuthLayoutContent = styled(Layout.Content)`
  background-color: transparent !important;
  height: 100%;
  padding: 15px;
  overflow: hidden;
`;

export { AuthLayout, AuthLayoutCornerHeader, AuthContentLayout, AuthLayoutHeader, AuthLayoutContent, AuthLayoutSider, AuthLayoutSideFooter };
