import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REDUCER_STATE } from '@client/constants/store';
import { getCurrentUser } from '@client/store/actions/auth';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { ROOT_ROUTES } from '@client/constants/routes';
import PropTypes from 'prop-types';
import Loading from '@client/components/loading';
import { MESSAGE } from '@client/constants/messages';
import { USER_ENUMS, USER_FIELDS } from '@client/constants/db-fields/user';

const LoginRoute = ({ component: Component, ...otherProps }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentUser = useSelector(state => state?.[REDUCER_STATE.AUTH.NAME]?.[REDUCER_STATE.AUTH.FIELDS.DATA])?.user;
  const isLoggedIn = useSelector(state => state?.[REDUCER_STATE.AUTH.NAME]?.[REDUCER_STATE.AUTH.FIELDS.IS_LOGGED_IN]);
  const isLogging = useSelector(state => state?.[REDUCER_STATE.AUTH.NAME]?.[REDUCER_STATE.AUTH.FIELDS.IS_LOGGING_IN]);
  const whichPageAfterLoggedin = [USER_ENUMS.ROLES.ADMIN, USER_ENUMS.ROLES.QA].includes(currentUser?.[USER_FIELDS.CURRENT_ROLE])
    ? ROOT_ROUTES.HOME
    : ROOT_ROUTES.FORM_ANSWERS;
  const redirectedFrom = location?.state?.redirectedFrom || whichPageAfterLoggedin;

  /**
   * Try login with existed session
   */
  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(getCurrentUser());
    }
  }, [dispatch, isLoggedIn]);

  /**
   * This is to prevent link with search cannot be redirected correctly
   */
  useEffect(() => {
    if (!isLogging && isLoggedIn) {
      history.push(redirectedFrom);
    }
  }, [history, isLogging, isLoggedIn, redirectedFrom]);

  if (isLogging) {
    return <Loading fullScreen={false} tip={MESSAGE.LOADING.DEFAULT} />;
  } else if (!isLoggedIn) {
    return <Route {...otherProps} render={props => <Component {...props} />} />;
  } else {
    return null;
    // return <Route {...otherProps} render={() => <Redirect to={{ pathname: redirectedFrom }} />} />;
  }
};

LoginRoute.propTypes = {
  component: PropTypes.object.isRequired,
};
export default LoginRoute;
