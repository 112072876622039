import BaseApi from './base';

class FormApi extends BaseApi {
  /**
   * Get form
   * @param {String} formId
   * @return {Promise<*>}
   */
  async getForm(formId) {
    const _self = this;
    try {
      const res = await _self.client.get(_self.endpoints.FORM({ formId }));
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Get templates
   * @param version
   * @return {Promise<*>}
   */
  async getForms(query) {
    const _self = this;
    try {
      const params = {};
      if (query.version) params.version = query.version;
      if (query.formIds) params.formIds = query.formIds;

      const res = await _self.client.get(_self.endpoints.FORMS, { params });
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Get list of answers
   * @param {Object} params
   * @return {Promise<*>}
   */
  async getFormAnswers(params) {
    const _self = this;
    try {
      const res = await _self.client.get(_self.endpoints.FORM_ANSWERS, { params });
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  async getFormAnswer({ formAnswerId }) {
    const _self = this;
    try {
      const res = await _self.client.get(_self.endpoints.FORM_ANSWER({ formAnswerId }));
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  async createFormAnswer({ formId, answer, subjectId, offeringCode, isFinishRequired, isSendForReview }) {
    const _self = this;
    try {
      const body = {
        formId,
        answer,
        subjectId,
        offeringCode,
        isFinishRequired,
        isSendForReview,
      };
      const res = await _self.client.post(_self.endpoints.FORM_ANSWERS, body);
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  async updateFormAnswer({ formAnswerId, answer, isFinishRequired, isSendForReview }) {
    const _self = this;
    try {
      const body = {
        answer,
        isFinishRequired,
        isSendForReview,
      };
      const res = await _self.client.put(_self.endpoints.FORM_ANSWER({ formAnswerId }), body);
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  async updateFormAnswerStatus({ formId, formAnswerId, status }) {
    const _self = this;
    try {
      const body = {
        status,
      };
      const res = await _self.client.put(_self.endpoints.FORM_ANSWER_STATUS({ formId, formAnswerId }), body);
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  async getCapsData({ offeringCode, subjectId, isKeepVersion = false }) {
    const _self = this;
    try {
      const body = {
        offeringCode,
        subjectId,
        isKeepVersion,
      };
      const res = await _self.client.get(_self.endpoints.FORM_INIT_ANSWER, { params: body });
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * download pdf
   * @param {string} formAnswerId
   * @return {Promise<*>}
   */
  async downloadPdf({ formAnswerId }) {
    const _self = this;
    try {
      const res = await _self.client.get(_self.endpoints.FORM_ANSWER_PDF({ formAnswerId }), {
        responseType: 'blob',
      });
      return res?.data;
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * download form answer as html
   * @param {string} formAnswerId
   * @return {Promise<*>}
   */
  async downloadFormAsHtml({ formAnswerId }) {
    const _self = this;
    try {
      const res = await _self.client.get(_self.endpoints.FORM_ANSWER_HTML({ formAnswerId }));
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Lock form answer
   * @param {string} formAnswerId
   * @return {Promise<*>}
   */
  async lockFormAnswer({ formAnswerId }) {
    const _self = this;
    try {
      const res = await _self.client.put(_self.endpoints.FORM_ANSWER_LOCK({ formAnswerId }));
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Unlock form answer
   * @param {string} formAnswerId
   * @return {Promise<*>}
   */
  async unlockFormAnswer({ formAnswerId }) {
    const _self = this;
    try {
      const res = await _self.client.put(_self.endpoints.FORM_ANSWER_UNLOCK({ formAnswerId }));
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Duplicate form answer
   * @param {String} formAnswerId
   * @param {String} offering
   * @return {Promise<*>}
   */
  async duplicateFormAnswer({ formAnswerId, offering }) {
    const _self = this;
    try {
      const res = await _self.client.post(_self.endpoints.FORM_ANSWER_DUPLICATE({ formAnswerId }), { offering });
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Delete form answer
   * @param {string} formAnswerId
   * @return {Promise<*>}
   */
  async deleteFormAnswer({ formAnswerId }) {
    const _self = this;
    try {
      const res = await _self.client.delete(_self.endpoints.FORM_ANSWER({ formAnswerId }));
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }
}

export default FormApi;
