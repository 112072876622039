import { combineReducers } from '@reduxjs/toolkit';
import { createHashHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import formSlice from '@client/store/reducers/form';
import { generalSlice } from '@client/store/reducers/general';
import { authSlice } from '@client/store/reducers/auth';
import { REDUCER_STATE } from '@client/constants/store';
import { userSlice } from '@client/store/reducers/user';
import { offeringSlice } from '@client/store/reducers/offering';
import { subjectSlice } from '@client/store/reducers/subject';
import { commentSlice } from '@client/store/reducers/comment';
import { departmentSlice } from '@client/store/reducers/department';

export const history = createHashHistory();
const createRootReducer = history =>
  combineReducers({
    [REDUCER_STATE.ROUTER.NAME]: connectRouter(history),
    [REDUCER_STATE.FORM.NAME]: formSlice.reducer,
    [REDUCER_STATE.GENERAL.NAME]: generalSlice.reducer,
    [REDUCER_STATE.AUTH.NAME]: authSlice.reducer,
    [REDUCER_STATE.USER.NAME]: userSlice.reducer,
    [REDUCER_STATE.OFFERING.NAME]: offeringSlice.reducer,
    [REDUCER_STATE.SUBJECT.NAME]: subjectSlice.reducer,
    [REDUCER_STATE.COMMENT.NAME]: commentSlice.reducer,
    [REDUCER_STATE.DEPARTMENT.NAME]: departmentSlice.reducer,
  });

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = function configureStore(preloadedState) {
  const store = createStore(createRootReducer(history), preloadedState, composeEnhancers(applyMiddleware(routerMiddleware(history), thunk)));
  return store;
};
