// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YYVY4Y9fNXf2K6E9UDpL {\n  align-items: center;\n  background-color: rgba(255, 255, 255, 0.9);\n  bottom: 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  left: 0;\n  position: absolute;\n  right: 0;\n  top: 0;\n  transition: all var(--transition-delay) var(--transition-timing-function);\n  z-index: 1001;\n}\n.YYVY4Y9fNXf2K6E9UDpL.EF0nAcNpXXBJuQpGAXFe {\n  height: 100vh;\n  position: fixed;\n  width: 100vw;\n}\n.YYVY4Y9fNXf2K6E9UDpL .FGRtCSbP_1GAC6fyLhpc {\n  color: #094183;\n}\n", "",{"version":3,"sources":["webpack://./src/client/components/loading/styles/index.module.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,0CAAA;EACA,SAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,OAAA;EACA,kBAAA;EACA,QAAA;EACA,MAAA;EACA,yEAAA;EACA,aAAA;AACF;AACE;EACE,aAAA;EACA,eAAA;EACA,YAAA;AACJ;AAlBA;EAqBI,cAAA;AAAJ","sourcesContent":[".loading__wrapper {\n  align-items: center;\n  background-color: rgba(255, 255, 255, 0.9);\n  bottom: 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  left: 0;\n  position: absolute;\n  right: 0;\n  top: 0;\n  transition: all var(--transition-delay) var(--transition-timing-function);\n  z-index: 1001;\n\n  &.fullscreen {\n    height: 100vh;\n    position: fixed;\n    width: 100vw;\n  }\n\n  .tip {\n    color: @primary-color;\n  }\n}\n\n@primary-color: #094183;@primary-color-light: #4888d0;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading__wrapper": "YYVY4Y9fNXf2K6E9UDpL",
	"fullscreen": "EF0nAcNpXXBJuQpGAXFe",
	"tip": "FGRtCSbP_1GAC6fyLhpc"
};
export default ___CSS_LOADER_EXPORT___;
