import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET_GIT_VERSION, GET_STATISTICAL_DATA } from '@client/store/types/general';
import { generalApi } from '@client/services/api';

/**
 * Get git version
 */
const getGitVersion = createAsyncThunk(GET_GIT_VERSION, async () => {
  return await generalApi.getGitVersion();
});

/**
 * Get statistical data
 */
const getStatisticalData = createAsyncThunk(GET_STATISTICAL_DATA, async () => {
  return await generalApi.getStatisticalData();
});

export { getGitVersion, getStatisticalData };
