import { COMMON_FIELDS } from '@client/constants/db-fields/shared';

const USER_ENUMS = {
  ROLES: {
    ACADEMIC: 'academic',
    ADMIN: 'admin',
    QA: 'qa',
  },
  DEPARTMENT: {
    ACCOUNTING: 'Accounting',
    ECONOMICS: 'Economics',
    FINANCE: 'Finance',
    MM: 'Management and Marketing',
  },
};

const USER_FIELDS = {
  ...COMMON_FIELDS,
  EMAIL: 'email',
  DEPARTMENT: 'department',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  PHONE: 'phone',
  PASSWORD: 'password',
  ASSIGNED_ROLES: 'assignedRoles',
  CURRENT_ROLE: 'currentRole',
  ROLE_SETTINGS: 'roleSettings',
};

const USER_ROLE_SETTING_FIELDS = {
  COORDINATING_SUBJECTS: 'coordinatingSubjects',
};

export { USER_ENUMS, USER_FIELDS, USER_ROLE_SETTING_FIELDS };
