import { createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_CURRENT, LOG_IN, LOG_OUT, UPDATE_PROFILE } from '@client/store/types/auth';
import { authApi, userApi } from '@client/services/api';
import { localforageClient } from '@client/services/localforage';
import { LOCAL_FORAGE_ITEM_KEY } from '@client/constants/localforage';

/**
 * Login
 */
const login = createAsyncThunk(LOG_IN, async ({ email, password, remember }) => {
  const res = await authApi.login({ email, password, remember });
  await localforageClient.setItem(LOCAL_FORAGE_ITEM_KEY.SESSION_TOKEN, res?.token);
  return res;
});

/**
 * Get current logged in user
 */
const getCurrentUser = createAsyncThunk(AUTH_CURRENT, async () => {
  return await authApi.auth();
});

/**
 * Get current logged in user
 */
const logout = createAsyncThunk(LOG_OUT, async () => {
  const res = await authApi.logout();
  await localforageClient.removeItem(LOCAL_FORAGE_ITEM_KEY.SESSION_TOKEN);
  return res;
});

/**
 * Update self profile
 */
const updateProfile = createAsyncThunk(UPDATE_PROFILE, async ({ userId, body }) => {
  return await userApi.updateUser({ userId, body });
});

export { login, getCurrentUser, logout, updateProfile };
