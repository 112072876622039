import BaseApi from './base';

class UserApi extends BaseApi {
  /**
   * Get list of users
   * @return {Promise<*>}
   */
  async getUsers(params) {
    const _self = this;
    try {
      const res = await _self.client.get(_self.endpoints.USERS, { params });
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Get user
   * @return {Promise<*>}
   */
  async getUser(userId) {
    const _self = this;
    try {
      const res = await _self.client.get(_self.endpoints.USER({ userId }));
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Create user
   * @return {Promise<*>}
   */
  async createUser(payload) {
    const _self = this;
    try {
      const body = payload;
      const params = {
        bulk: false,
      };
      const res = await _self.client.post(_self.endpoints.USERS, body, { params });
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Create users
   * @return {Promise<*>}
   */
  async createUsers(payload) {
    const _self = this;
    try {
      const body = { users: payload };
      const params = {
        bulk: true,
      };
      const res = await _self.client.post(_self.endpoints.USERS, body, { params });
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Update users
   * @return {Promise<*>}
   */
  async updateUser({ userId, body }) {
    const _self = this;
    try {
      const res = await _self.client.put(_self.endpoints.USER({ userId }), body);
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Delete users
   * @return {Promise<*>}
   */
  async deleteUser(payload) {
    const _self = this;
    try {
      const res = await _self.client.delete(_self.endpoints.USER({ userId: payload }));
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }
}

export { UserApi };
