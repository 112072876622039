import BaseApi from './base';

class DepartmentApi extends BaseApi {
  /**
   * Get department
   * @return {Promise<*>}
   */
  async getDepartment(id) {
    const _self = this;
    try {
      const res = await _self.client.get(_self.endpoints.DEPARTMENT({ id }));
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Get list of departments
   * @return {Promise<*>}
   */
  async getDepartments(params) {
    const _self = this;
    try {
      const res = await _self.client.get(_self.endpoints.DEPARTMENTS, { params });
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Create department
   * @return {Promise<*>}
   */
  async createDepartment(payload) {
    const _self = this;
    try {
      const res = await _self.client.post(_self.endpoints.DEPARTMENTS, payload);
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Update department
   * @return {Promise<*>}
   */
  async updateDepartment({ id, payload }) {
    const _self = this;
    try {
      const res = await _self.client.put(_self.endpoints.DEPARTMENT({ id }), payload);
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Delete department
   * @return {Promise<*>}
   */
  async deleteDepartment(id) {
    const _self = this;
    try {
      const res = await _self.client.delete(_self.endpoints.DEPARTMENT({ id }));
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }
}

export { DepartmentApi };
