const extractResponse = res => res.data.data;

const errorHandler = err => {
  if (err.response) {
    const msg = err.response.data?.error || err.response.data;
    const status = err.response.status;
    const newError = new Error(msg);
    newError.status = status;
    throw newError;
  } else {
    throw err;
  }
};

export { errorHandler, extractResponse };
