import { createAsyncThunk } from '@reduxjs/toolkit';
import { formApi } from '@client/services/api';
import {
  CREATE_FORM_ANSWER,
  DELETE_FORM_ANSWER,
  DOWNLOAD_FORM_ANSWER_DATA_AS_HTML,
  DOWNLOAD_FORM_ANSWER_DATA_AS_PDF,
  DUPLICATE_FORM_ANSWER,
  GET_CAPS_DATA,
  GET_FORM,
  GET_FORM_ANSWER,
  GET_FORM_ANSWERS,
  GET_FORMS,
  GET_HISTORY_FORM_ANSWERS,
  GET_HISTORY_FORMS,
  LOCK_FORM_ANSWER,
  UNLOCK_FORM_ANSWER,
  UPDATE_FORM_ANSWER,
  UPDATE_FORM_ANSWER_STATUS,
} from '@client/store/types/form';

/**
 * Get form template
 * FIXME: it only get one form
 */
const getForm = createAsyncThunk(GET_FORM, async id => {
  const form = await formApi.getForm(id);
  return form;
});

/**
 * Get forms
 */
const getForms = createAsyncThunk(GET_FORMS, async (query = {}) => {
  return await formApi.getForms(query);
});

/**
 * Get all answers for this template
 */
const getFormAnswers = createAsyncThunk(GET_FORM_ANSWERS, async payload => {
  return await formApi.getFormAnswers(payload);
});

/**
 * Get past form answers
 */
const getHistoryFormAnswers = createAsyncThunk(GET_HISTORY_FORM_ANSWERS, async payload => {
  return await formApi.getFormAnswers({ ...payload, getHistoryAnswers: true });
});

/**
 * Get past forms
 */
const getHistoryForms = createAsyncThunk(GET_HISTORY_FORMS, async payload => {
  return await formApi.getForms(payload);
});

/**
 * Get one answer
 */
const getFormAnswer = createAsyncThunk(GET_FORM_ANSWER, async payload => {
  return await formApi.getFormAnswer(payload);
});

const createFormAnswer = createAsyncThunk(CREATE_FORM_ANSWER, async payload => {
  return await formApi.createFormAnswer(payload);
});

const updateFormAnswer = createAsyncThunk(UPDATE_FORM_ANSWER, async payload => {
  return await formApi.updateFormAnswer(payload);
});

const updateFormAnswerStatus = createAsyncThunk(UPDATE_FORM_ANSWER_STATUS, async payload => {
  return await formApi.updateFormAnswerStatus(payload);
});

const getCapsData = createAsyncThunk(GET_CAPS_DATA, async payload => {
  return await formApi.getCapsData(payload);
});

const downloadAnswerAsPdf = createAsyncThunk(DOWNLOAD_FORM_ANSWER_DATA_AS_PDF, async payload => {
  return await formApi.downloadPdf(payload);
});

const downloadAnswerAsHtml = createAsyncThunk(DOWNLOAD_FORM_ANSWER_DATA_AS_HTML, async ({ formId, formAnswerId }) => {
  return await formApi.downloadFormAsHtml({ formAnswerId });
});

const lockFormAnswer = createAsyncThunk(LOCK_FORM_ANSWER, async ({ formAnswerId }) => {
  return await formApi.lockFormAnswer({ formAnswerId });
});

const unlockFormAnswer = createAsyncThunk(UNLOCK_FORM_ANSWER, async ({ formAnswerId }) => {
  return await formApi.unlockFormAnswer({ formAnswerId });
});

const duplicateFormAnswer = createAsyncThunk(DUPLICATE_FORM_ANSWER, async ({ formAnswerId, offering }) => {
  return await formApi.duplicateFormAnswer({ formAnswerId, offering });
});

const deleteFormAnswer = createAsyncThunk(DELETE_FORM_ANSWER, async ({ formAnswerId }) => {
  await formApi.deleteFormAnswer({ formAnswerId });
  return formAnswerId;
});

export {
  lockFormAnswer,
  unlockFormAnswer,
  getForm,
  getForms,
  getFormAnswers,
  getHistoryForms,
  getHistoryFormAnswers,
  getFormAnswer,
  deleteFormAnswer,
  createFormAnswer,
  updateFormAnswer,
  updateFormAnswerStatus,
  getCapsData,
  downloadAnswerAsPdf,
  downloadAnswerAsHtml,
  duplicateFormAnswer,
};
