import styled from 'styled-components';
import { Breadcrumb } from 'antd';
import background from '@client/static/bg.svg';

/**
 * Layout - Global wrapper for whole app
 */
const GlobalWrapper = styled.div`
  display: block;
  width: 100vw;
  height: 100vh;
  background-image: url(${background});
  background-size: auto 100%;
`;

/**
 * Display content in center
 */
const CenterWrapper = styled.div`
  display: flex;
  height: ${props => (props.height ? props.height : 'default')};
  flex-direction: ${props => (props.isHorizontal ? 'row' : 'column')};
  justify-content: center;
  align-items: center;
  gap: ${props => (props.size ? props.size + 'px' : 'default')};
  margin: ${props => (props.margin ? props.margin + 'px' : 'default')};
`;

/**
 * Display content horizontally and in the 2 end
 */
const HorizontalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

/**
 * Route path wrapper
 */
const RoutePathWrapper = styled(Breadcrumb)`
  font-size: 18px;
  .ant-breadcrumb-link {
    color: black;
    a {
      color: black !important;
    }
  }
  .ant-breadcrumb-separator {
    color: black;
  }
`;
/**
 * Text - alert
 */
const AlertTextWrapper = styled.div`
  color: red;
  font-style: italic;
  margin-top: 10px;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export { IconWrapper, AlertTextWrapper, GlobalWrapper, CenterWrapper, RoutePathWrapper, HorizontalWrapper };
