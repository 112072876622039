import { createSlice } from '@reduxjs/toolkit';
import { REDUCER_STATE } from '@client/constants/store';
import { createComment, deleteComment, getComment, updateComment } from '@client/store/actions/comment';
import { findIndex } from 'lodash';
import { COMMON_FIELDS } from '@client/constants/db-fields/shared';

const {
  COMMENT: { FIELDS },
} = REDUCER_STATE;

const initialState = {
  [FIELDS.ERROR]: null,
  [FIELDS.COMMENTS]: null,
  [FIELDS.IS_GETTING]: false,
  [FIELDS.IS_CREATING]: false,
  [FIELDS.IS_UPDATING]: false,
};

const reducers = {};

const extraReducers = {
  [getComment.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING]: true,
    [FIELDS.COMMENTS]: null,
  }),
  [getComment.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING]: false,
    [FIELDS.COMMENTS]: action.payload,
  }),
  [getComment.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [createComment.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_CREATING]: true,
  }),
  [createComment.fulfilled]: (state, action) => {
    return {
      ...state,
      [FIELDS.IS_CREATING]: false,
      [FIELDS.COMMENTS]: [action.payload, ...(state?.[FIELDS.COMMENTS] || [])],
    };
  },
  [createComment.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_CREATING]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [updateComment.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_UPDATING]: true,
  }),
  [updateComment.fulfilled]: (state, action) => {
    const currentComments = state?.[FIELDS.COMMENTS];
    const index = findIndex(currentComments, { [COMMON_FIELDS.ID]: action.payload?.[COMMON_FIELDS.ID] });
    let result = currentComments;
    if (index !== -1) {
      result = [...(currentComments?.slice(0, index) || []), action.payload, ...(currentComments?.slice(index + 1) || [])];
    }
    return {
      ...state,
      [FIELDS.IS_UPDATING]: false,
      [FIELDS.COMMENTS]: result,
    };
  },
  [updateComment.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_UPDATING]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [deleteComment.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_DELETING]: true,
  }),
  [deleteComment.fulfilled]: (state, action) => {
    const currentComments = state?.[FIELDS.COMMENTS];
    const index = findIndex(currentComments, { [COMMON_FIELDS.ID]: action.payload });
    let result = currentComments;
    if (index !== -1) {
      result = [...(currentComments?.slice(0, index) || []), ...(currentComments?.slice(index + 1) || [])];
    }
    return {
      ...state,
      [FIELDS.IS_DELETING]: false,
      [FIELDS.COMMENTS]: result,
    };
  },
  [deleteComment.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_DELETING]: false,
    [FIELDS.ERROR]: action.error,
  }),
};
const commentSlice = createSlice({
  name: REDUCER_STATE.COMMENT.NAME,
  initialState,
  reducers,
  extraReducers,
});

export { commentSlice };
