import React from 'react';
import { ADMIN_ROUTES, ROOT_ROUTES } from '@client/constants/routes';
import { RoutePathWrapper } from '@client/styles/wrapper';
import { matchPath, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';

const PathBreadcrumb = () => {
  const location = useLocation();
  const { pathname } = location;
  if (pathname === ROOT_ROUTES.HOME) {
    return <RoutePathWrapper items={[{ href: '', title: 'Dashboard' }]} />;
  } else if (!isEmpty(matchPath(pathname, { path: ROOT_ROUTES.USER, exact: true, strict: false }))) {
    return <RoutePathWrapper items={[{ title: 'Profile' }]} />;
  } else if (matchPath(pathname, { path: ADMIN_ROUTES.SUBJECTS, exact: false, strict: false })) {
    return <RoutePathWrapper items={[{ path: ROOT_ROUTES.ADMIN, title: 'Admin' }, { title: 'Subjects' }]} />;
  } else if (matchPath(pathname, { path: ROOT_ROUTES.FORM_ANSWERS, exact: false, strict: false })) {
    return <RoutePathWrapper items={[{ title: 'Subject Guides' }]} />;
  } else if (matchPath(pathname, { path: ADMIN_ROUTES.USERS, exact: true, strict: false })) {
    return <RoutePathWrapper items={[{ title: 'Admin', path: ROOT_ROUTES.ADMIN }, { title: 'Users' }]} />;
  } else if (matchPath(pathname, { path: ROOT_ROUTES.ADMIN, exact: true, strict: false })) {
    return <RoutePathWrapper items={[{ title: 'Admin' }]} />;
  } else {
    return null;
  }
};

export default PathBreadcrumb;
