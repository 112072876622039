import BaseApi from '@client/services/api/modules/base';

class AuthApi extends BaseApi {
  /**
   * Login
   * @param {string} email
   * @param {string} password
   * @param {Boolean} remember - whether remember session
   * @return {Promise<*>}
   */
  async login({ email, password, remember }) {
    const _self = this;
    try {
      const res = await _self.client.post(_self.endpoints.LOGIN, { email, password, remember });
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Get current logged in user
   * @return {Promise<*>}
   */
  async auth() {
    const _self = this;
    try {
      return _self.postProcessor(await _self.client.get(_self.endpoints.CURRENT));
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Logout
   * @return {Promise<*>}
   */
  async logout() {
    const _self = this;
    try {
      return _self.postProcessor(await _self.client.post(_self.endpoints.LOGOUT));
    } catch (err) {
      _self.errorHandler(err);
    }
  }
}

export { AuthApi };
