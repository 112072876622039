import FormApi from '@client/services/api/modules/form';
import { GeneralApi } from '@client/services/api/modules/general';
import { AuthApi } from '@client/services/api/modules/auth';
import { UserApi } from '@client/services/api/modules/user';
import { OfferingApi } from '@client/services/api/modules/offering';
import { SubjectApi } from '@client/services/api/modules/subject';
import { CommentApi } from '@client/services/api/modules/comment';
import { DepartmentApi } from '@client/services/api/modules/department';

const formApi = new FormApi();
const generalApi = new GeneralApi();
const authApi = new AuthApi();
const userApi = new UserApi();
const offeringApi = new OfferingApi();
const subjectApi = new SubjectApi();
const commentApi = new CommentApi();
const departmentApi = new DepartmentApi();

export { authApi, formApi, generalApi, userApi, offeringApi, subjectApi, commentApi, departmentApi };
