import { createAsyncThunk } from '@reduxjs/toolkit';
import { departmentApi } from '@client/services/api';
import { CREATE_DEPARTMENT, DELETE_DEPARTMENT, GET_DEPARTMENT, GET_DEPARTMENTS, UPDATE_DEPARTMENT } from '@client/store/types/department';

/**
 * Get departments
 */
const getDepartments = createAsyncThunk(GET_DEPARTMENTS, async params => {
  return await departmentApi.getDepartments(params);
});

/**
 * Get department
 */
const getDepartment = createAsyncThunk(GET_DEPARTMENT, async id => {
  return await departmentApi.getDepartment(id);
});

/**
 * Create department
 */
const createDepartment = createAsyncThunk(CREATE_DEPARTMENT, async payload => {
  return await departmentApi.createDepartment(payload);
});

/**
 * Update department
 */
const updateDepartment = createAsyncThunk(UPDATE_DEPARTMENT, async ({ id, payload }) => {
  return await departmentApi.updateDepartment({ id, payload });
});

/**
 * Delete department
 */
const deleteDepartment = createAsyncThunk(DELETE_DEPARTMENT, async payload => {
  await departmentApi.deleteDepartment(payload);
  return payload;
});

export { getDepartments, getDepartment, createDepartment, updateDepartment, deleteDepartment };
