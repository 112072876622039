const ROOT_ROUTES = {
  ALL: '*',
  HOME: '/dashboard',
  OFFERINGS: '/offerings', // Discard
  FORM_ANSWERS: '/subject-guides',
  LOG_IN: '/login',
  FORGOT_PASSWORD: '/forgot-password', // Discard
  TEMPLATE: '/template', // Discard
  USERS: '/users',
  USER: '/users/:userId',
  SUBJECTS: '/subjects',
  ADMIN: '/admin',
};

const FORM_ANSWERS_ROUTES = {
  EXIST_FORM_ANSWERS: `${ROOT_ROUTES.FORM_ANSWERS}`,
  NEW_FORM: `${ROOT_ROUTES.FORM_ANSWERS}/:formId/:offeringCode/:subjectId/new`,
  EXIST_FORM_ANSWER: `${ROOT_ROUTES.FORM_ANSWERS}/:formAnswerId`,
};

const ADMIN_ROUTES = {
  ROOT: `${ROOT_ROUTES.ADMIN}`,
  USERS: `${ROOT_ROUTES.ADMIN}/users`,
  SUBJECTS: `${ROOT_ROUTES.ADMIN}/subjects`,
};

const SEARCH_PARAMS = {
  DEPARTMENT: 'department',
};

export { ROOT_ROUTES, FORM_ANSWERS_ROUTES, ADMIN_ROUTES, SEARCH_PARAMS };
