export const GET_FORM = 'forms/getForm';
export const GET_FORMS = 'forms/getForms';
export const GET_FORM_ANSWERS = 'forms/getFormAnswers';
export const GET_HISTORY_FORM_ANSWERS = 'forms/getHistoryFormAnswers';
export const GET_HISTORY_FORMS = 'forms/getHistoryForms';
export const GET_FORM_ANSWER = 'forms/getFormAnswer';
export const CREATE_FORM_ANSWER = 'forms/createFormAnswer';
export const UPDATE_FORM_ANSWER = 'forms/updateFormAnswer';
export const UPDATE_FORM_ANSWER_STATUS = 'forms/updateFormAnswerStatus';
export const DELETE_FORM_ANSWER = 'forms/deleteFormAnswer';
export const GET_CAPS_DATA = 'forms/getCapsData';
export const DOWNLOAD_FORM_ANSWER_DATA_AS_PDF = 'forms/downloadFormAnswerDataAsPdf';
export const DOWNLOAD_FORM_ANSWER_DATA_AS_HTML = 'forms/downloadFormAnswerDataAsHtml';
export const LOCK_FORM_ANSWER = 'forms/lockFormAnswer';
export const UNLOCK_FORM_ANSWER = 'forms/unlockFormAnswer';

export const DUPLICATE_FORM_ANSWER = 'forms/duplicateFormAnswer';
