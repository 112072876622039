import { ttl } from '@client/libs/utils';

const ENDPOINTS = {
  LOGIN: '/auth/login',
  LOGOUT: '/auth/logout',
  CURRENT: '/auth/current',
  FORMS: '/forms',
  FORM: ttl`/forms/${'formId'}`,
  FORM_ANSWERS: '/form-answers',
  FORM_ANSWER: ttl`/form-answers/${'formAnswerId'}`,
  FORM_ANSWER_STATUS: ttl`/form-answers/${'formAnswerId'}/status`,
  FORM_ANSWER_LOCK: ttl`/form-answers/${'formAnswerId'}/lock`,
  FORM_ANSWER_UNLOCK: ttl`/form-answers/${'formAnswerId'}/unlock`,
  FORM_ANSWER_DUPLICATE: ttl`/form-answers/${'formAnswerId'}/duplicate`,
  FORM_INIT_ANSWER: '/forms/prefill/caps',
  FORM_ANSWER_PDF: ttl`/form-answers/${'formAnswerId'}/pdf`,
  FORM_ANSWER_HTML: ttl`/form-answers/${'formAnswerId'}/html`,
  // General
  GIT_VERSION: 'general/version',
  STATISTICAL_DATA: 'general/statistics',
  // Department
  DEPARTMENTS: '/departments',
  DEPARTMENT: ttl`/departments/${'id'}`,
  // User
  USERS: '/users',
  USER: ttl`/users/${'userId'}`,
  // Offering
  OFFERINGS: '/offerings',
  OFFERING: ttl`/offerings/${'offeringId'}`,
  OFFERINGS_YEARS: '/offerings/years',
  // Subject
  SUBJECTS: '/subjects',
  SUBJECT: ttl`/subjects/${'subjectId'}`,
  // Comment
  COMMENTS: '/comments',
  COMMENT: ttl`/comments/${'commentId'}`,
  // Form Prefill Data
  FORM_PREFILL_DATAS: '/form-prefill-data',
  FORM_PREFILL_DATA: ttl`/form-prefill-data/${'id'}`,
};

export default ENDPOINTS;
