const DEFAULT_STATE = {
  DATA: 'data',
  ERROR: 'error',
  IS_UPDATING: 'isUpdating',
  IS_GETTING: 'isGetting',
  IS_CREATING: 'isCreating',
  IS_DELETING: 'isDeleting',
};

const REDUCER_STATE = {
  ROUTER: {
    NAME: 'router',
  },
  AUTH: {
    NAME: 'auth',
    FIELDS: {
      ERROR: DEFAULT_STATE.ERROR,
      DATA: DEFAULT_STATE.DATA,
      IS_LOGGING_IN: 'isLoggingIn',
      IS_LOGGING_OUT: 'isLoggingOut',
      IS_VERIFYING: 'isVerifying',
      IS_LOGGED_IN: 'isLoggedIn',
      IS_UPDATING_PROFILE: 'isUpdatingProfile',
    },
  },
  DEPARTMENT: {
    NAME: 'department',
    FIELDS: {
      ERROR: DEFAULT_STATE.ERROR,
      IS_GETTING: DEFAULT_STATE.IS_GETTING,
      IS_CREATING: DEFAULT_STATE.IS_CREATING,
      IS_UPDATING: DEFAULT_STATE.IS_UPDATING,
      IS_DELETING: DEFAULT_STATE.IS_DELETING,
      DEPARTMENTS: 'departments',
      DEPARTMENT: 'department',
    },
  },
  FORM: {
    NAME: 'form',
    FIELDS: {
      ERROR: DEFAULT_STATE.ERROR,
      FORM: 'form',
      FORMS: 'forms',
      FORM_ANSWERS: 'formAnswers',
      HISTORY_FORM_ANSWERS: 'historyFormAnswers',
      HISTORY_FORMS: 'historyForms',
      PDF_DATA: 'pdfData',
      FORM_ANSWER: 'formAnswer',
      CAPS_DATA: 'capsData',
      HTML_DATA: 'htmlData',
      IS_GETTING_FORM: 'isGettingForm',
      IS_GETTING_FORM_ANSWER: 'isGettingFormAnswer',
      IS_UPDATING_FORM_ANSWER: 'isUpdatingFormAnswer',
      IS_CREATING_FORM_ANSWER: 'isCreatingFormAnswer',
      IS_GETTING_PREFILLED_DATA: 'isGettingPrefilledData',
      IS_DELETING_FORM_ANSWER: 'isDeletingFormAnswer',
      IS_UPDATING_FORM_ANSWER_STATUS: 'isUpdatingFormAnswerStatus',
      IS_LOCKING: 'isLocking',
      IS_UNLOCKING: 'isUnlocking',
      IS_DUPLICATING: 'isDuplicating',
      IS_DOWNLOADING: 'isDownloading',
      IS_DOWNLOADING_HTML: 'isDownloadingHtml',
    },
  },
  COMMENT: {
    NAME: 'comment',
    FIELDS: {
      ERROR: DEFAULT_STATE.ERROR,
      COMMENTS: 'comments',
      IS_GETTING: DEFAULT_STATE.IS_GETTING,
      IS_CREATING: DEFAULT_STATE.IS_CREATING,
      IS_UPDATING: DEFAULT_STATE.IS_UPDATING,
      IS_DELETING: DEFAULT_STATE.IS_DELETING,
    },
  },
  OFFERING: {
    NAME: 'offering',
    FIELDS: {
      ERROR: DEFAULT_STATE.ERROR,
      OFFERINGS: 'offerings',
      AVAILABLE_YEARS: 'availableYears',
      IS_GETTING_OFFERING: 'isGettingOffering',
      IS_GETTING_YEARS: 'isGettingYears',
      IS_CREATING: 'isCreating',
      IS_DELETING: DEFAULT_STATE.IS_DELETING,
    },
  },
  SUBJECT: {
    NAME: 'subject',
    FIELDS: {
      ERROR: DEFAULT_STATE.ERROR,
      SUBJECTS: 'subjects',
      SUBJECT: 'subject',
      IS_GETTING_SUBJECT: 'isGettingSubject',
      IS_CREATING: 'isCreating',
      IS_CREATING_SUBJECTS: 'isCreatingSubjects',
      IS_DELETING: 'isDeleting',
      IS_UPDATING: DEFAULT_STATE.IS_UPDATING,
    },
  },
  USER: {
    NAME: 'user',
    FIELDS: {
      ERROR: DEFAULT_STATE.ERROR,
      IS_GETTING_USER: 'isGettingUser',
      IS_CREATING_USER: 'isCreatingUser',
      IS_UPDATING_USER: 'isUpdatingUser',
      IS_CREATING_USERS: 'isCreatingUsers',
      IS_DELETING_USER: 'isDeletingUser',
      USERS: 'users',
      USER: 'user',
    },
  },
  GENERAL: {
    NAME: 'general',
    FIELDS: {
      ERROR: DEFAULT_STATE.ERROR,
      GIT_VERSION: 'gitVersion',
      STATISTICS_DATA: 'statisticsData',
      IS_GETTING_STATISTICAL_DATA: 'isGettingStatisticalData',
      IS_GETTING_GIT_VERSION: 'isGettingGitVersion',
    },
  },
};

export { REDUCER_STATE };
