import { ttl } from '@client/libs/utils';
import { USER_ENUMS } from '@client/constants/db-fields/user';

const MESSAGE = {
  VALIDATION: {
    EMPTY_EMAIL: 'Please input the email',
    EMPTY_PASSWORD: 'Please input the password',
    EMPTY_FIRST_NAME: 'Please input the first name',
    EMPTY_LAST_NAME: 'Please input the last name',
    EMPTY_ROLE: 'Please input the role',
    EMPTY_COORDINATING_SUBJECTS: 'Please select the courses you are teaching',
    EMPTY_DEPARTMENT: 'Please input the department',
    EMPTY_SEMESTER: 'Please select the semester',
    EMPTY_SUBJECT_CODE: 'Please input the subject code',
    EMPTY_SUBJECT_NAME: 'Please input the subject name',
    EMPTY_SUBJECT_COORDINATOR: 'Please select the coordinator',
    WRONG_EMAIL_FORMAT: 'The email format is not correct',
    EMPTY_PRIVACY: 'At least 1 privacy please',
    EMPTY_PRIVACY_CONTENT: 'Please input privacy or delete this field',
    EMPTY_AEA_CONTENT: 'The content cannot be empty',
    ADMIN_CANNOT_HAS_OTHER_ROLES: 'Admin user cannot have other roles',
  },
  PLACEHOLDER: {
    EMAIL: 'Email',
    DEPARTMENT: 'Department',
    FIRST_NAME: 'First name',
    LAST_NAME: 'Last name',
    ROLE: 'Role',
    PHONE: 'Phone number',
    PASSWORD: 'Password',
    COORDINATING_SUBJECTS: 'Coordinating subjects',
    SEMESTER: 'Semester',
    SUBJECT_NAME: 'Subject name',
    SUBJECT_COORDINATOR: 'Coordinator',
    COMMENT_QUESTION: 'You can select a question',
    COMMENT: 'Please input your comment',
    COMMENT_REPLY: ttl`Reply to ${'name'}`,
  },
  CONFIRMATION: {
    LOGOUT: 'Are you sure to logout?',
    DELETE_OFFERING: 'Are you sure to delete this offering?',
    DELETE_SUBJECT: 'Are you sure to delete this subject?',
    DELETE_FORM: 'Are you sure to delete this guide?',
    PUBLISH_GUIDES: 'Are you sure to publish this guide (cannot be undo)',
    DELETE_DEPARTMENT: 'Are you sure to delete this department? It will delete all subjects, guides, and users associated with this department',
  },
  LOADING: {
    DEFAULT: 'Loading...',
    INITIALIZE: 'Initializing...',
    VERIFYING: 'Verifying...',
    CREATING: 'Creating...',
    UPDATING: 'Updating...',
    GETTING: 'Getting...',
    DELETING: 'Deleting...',
    DOWNLOADING: 'Downloading',
  },
  PROMPT: {
    FORM_ANSWER_CREATED: 'Answer submitted successfully.',
    FORM_ANSWER_UPDATED: 'Answer updated successfully.',
    FORM_ANSWER_EDITED_BY_OTHERS: 'Subject guide is being used by another user at the moment.',
    PROFILE_UPDATED: 'Profile updated successfully.',
  },
  TOOLTIP: {
    EDIT_DEPARTMENT: 'Edit department',
    EDIT_CUSTOM_DATA: 'Manage custom subject guide value',
    MANAGE_USERS: 'Manage users',
    MANAGE_SUBJECTS: 'Manage subjects',
    MANAGE_TEMPLATE: 'Manage template',
    DELETE_DEPARTMENT: 'Delete this department',
    REVIEWED_STATUS: 'Set guides as reviewed',
    ACTION_REQUIRED_STATUS: 'Set guides as action required to notify academic',
    WITHDRAW_AND_CHANGE_BACK_TO_REVIEWING_STATUS: 'Withdraw and change it back to sent for reviewing',
    ROLE_INSTRUCTIONS: {
      [USER_ENUMS.ROLES.ADMIN]: [
        'Add, edit and delete departments',
        'Add, edit and delete users',
        'Add, edit and delete subjects',
        'Edit template for each department',
        'Leave comments to all subject guides',
      ],
      [USER_ENUMS.ROLES.ACADEMIC]: [
        'Add new subject guides for your coordinating subjects',
        'Edit existed subject guides for your coordinating subjects, either created by you or your colleague',
        'Leave or reply comments for all accessible subject guides',
      ],
      [USER_ENUMS.ROLES.QA]: ['Review and leave comments to all subject guides within your department'],
    },
  },
};

export { MESSAGE };
