import React, { lazy, Suspense, useEffect } from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import Loading from '@client/components/loading';
import { ROOT_ROUTES } from '@client/constants/routes';
import AuthRoute from '@client/routes/wrapper/authRoute';
import LoginRoute from '@client/routes/wrapper/loginRoute';
import { useDispatch } from 'react-redux';
import { getGitVersion } from '@client/store/actions/general';

const HomePageView = lazy(() => import('@client/views/home'));
// const OfferingPageView = lazy(() => import('@client/views/offering'));
// const TemplatePageView = lazy(() => import('@client/views/template'));
const LoginView = lazy(() => import('@client/views/login'));
const FormAnswersView = lazy(() => import('@client/views/survey-form'));
const UserView = lazy(() => import('@client/views/user/profile'));
const AdminRoute = lazy(() => import('@client/routes/admin'));

const RootRoutes = () => {
  const dispatch = useDispatch();
  /**
   * Get git version
   */
  useEffect(() => {
    dispatch(getGitVersion());
  }, [dispatch]);

  return (
    <HashRouter getUserConfirmation={() => {}}>
      <Suspense fallback={<Loading fullscreen={true} />}>
        <Switch>
          <LoginRoute path={ROOT_ROUTES.LOG_IN} component={LoginView} />
          <AuthRoute path={ROOT_ROUTES.HOME} component={HomePageView} />
          {/*<AuthRoute path={ROOT_ROUTES.OFFERINGS} component={OfferingPageView} />*/}
          {/*<AuthRoute path={ROOT_ROUTES.TEMPLATE} component={TemplatePageView} />*/}
          <AuthRoute path={ROOT_ROUTES.FORM_ANSWERS} component={FormAnswersView} />
          <AuthRoute path={ROOT_ROUTES.USER} component={UserView} />
          <AuthRoute path={ROOT_ROUTES.ADMIN} component={AdminRoute} />
          <Route path={ROOT_ROUTES.ALL}>
            <Redirect to={ROOT_ROUTES.HOME} />
          </Route>
        </Switch>
      </Suspense>
    </HashRouter>
  );
};

export default RootRoutes;
