// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/normalize.css/normalize.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/antd/dist/reset.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/@fontsource/source-sans-pro/index.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  overflow: hidden;\n  line-height: 1.5;\n}\n#root {\n  --primary-color: #094183;\n  --primary-color-light: #4888d0;\n  --transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);\n  height: 100vh;\n  position: relative;\n  width: 100vw;\n  background-image: linear-gradient(90deg, var(--primary-color), var(--primary-color-light));\n  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Open Sans', 'Helvetica Neue', sans-serif !important;\n  font-size: 15px !important;\n}\n#root .ant-btn {\n  border-radius: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/client/styles/index.less"],"names":[],"mappings":"AAIA;EACE,gBAAA;EACA,gBAAA;AAAF;AAGA;EACE,wBAAA;EACA,8BAAA;EACA,kEAAA;EACA,aAAA;EACA,kBAAA;EACA,YAAA;EACA,0FAAA;EACA,2IAAA;EACA,0BAAA;AADF;AARA;EAYI,mBAAA;AADJ","sourcesContent":["@import '~normalize.css';\n@import '~antd/dist/reset.css';\n@import '~@fontsource/source-sans-pro/index.css';\n\nbody {\n  overflow: hidden;\n  line-height: 1.5;\n}\n\n#root {\n  --primary-color: #094183;\n  --primary-color-light: #4888d0;\n  --transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);\n  height: 100vh;\n  position: relative;\n  width: 100vw;\n  background-image: linear-gradient(90deg, var(--primary-color), var(--primary-color-light));\n  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Open Sans', 'Helvetica Neue', sans-serif !important;\n  font-size: 15px !important;\n\n  .ant-btn {\n    border-radius: 10px;\n  }\n}\n@primary-color: #094183;@primary-color-light: #4888d0;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
