import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET_SUBJECTS, CREATE_SUBJECT, DELETE_SUBJECT, UPDATE_SUBJECT, CREATE_SUBJECTS } from '@client/store/types/subject';
import { subjectApi } from '@client/services/api';

/**
 * Get subjects
 */
const getSubjects = createAsyncThunk(GET_SUBJECTS, async params => {
  return await subjectApi.getSubjects(params);
});

/**
 * Create subject
 */
const createSubject = createAsyncThunk(CREATE_SUBJECT, async payload => {
  return await subjectApi.createSubject(payload);
});

/**
 * Create subjects
 */
const createSubjects = createAsyncThunk(CREATE_SUBJECTS, async payload => {
  return await subjectApi.createSubjects(payload);
});

/**
 * Update subject
 */
const updateSubject = createAsyncThunk(UPDATE_SUBJECT, async ({ subjectId, payload }) => {
  return await subjectApi.updateSubject({ subjectId, payload });
});

/**
 * Delete subject
 */
const deleteSubject = createAsyncThunk(DELETE_SUBJECT, async payload => {
  await subjectApi.deleteSubject(payload);
  return payload;
});

export { getSubjects, createSubject, createSubjects, updateSubject, deleteSubject };
