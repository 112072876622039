import { createSlice } from '@reduxjs/toolkit';
import { findIndex } from 'lodash';
import { REDUCER_STATE } from '@client/constants/store';
import { COMMON_FIELDS } from '@client/constants/db-fields/shared';
import { createDepartment, deleteDepartment, getDepartment, getDepartments, updateDepartment } from '@client/store/actions/department';

const {
  DEPARTMENT: { FIELDS },
} = REDUCER_STATE;

const initialState = {
  [FIELDS.ERROR]: null,
  [FIELDS.DEPARTMENTS]: [],
  [FIELDS.DEPARTMENT]: null,
  [FIELDS.IS_GETTING]: false,
  [FIELDS.IS_CREATING]: false,
  [FIELDS.IS_DELETING]: false,
  [FIELDS.IS_UPDATING]: false,
};

const reducers = {
  setCurrentDepartment: (state, action) => {
    state[FIELDS.DEPARTMENT] = action.payload;
  },
};

const extraReducers = {
  [getDepartment.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING]: true,
    [FIELDS.DEPARTMENT]: null,
  }),
  [getDepartment.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING]: false,
    [FIELDS.DEPARTMENT]: action.payload,
  }),
  [getDepartment.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING]: false,
    [FIELDS.ERROR]: action.error,
  }),

  [getDepartments.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING]: true,
    [FIELDS.DEPARTMENTS]: [],
  }),
  [getDepartments.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING]: false,
    [FIELDS.DEPARTMENTS]: action.payload,
  }),
  [getDepartments.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING]: false,
    [FIELDS.ERROR]: action.error,
  }),

  [createDepartment.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_CREATING]: true,
  }),
  [createDepartment.fulfilled]: (state, action) => {
    const current = state?.[FIELDS.DEPARTMENTS];
    return {
      ...state,
      [FIELDS.IS_CREATING]: false,
      [FIELDS.DEPARTMENTS]: [...current, action.payload],
    };
  },
  [createDepartment.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_CREATING]: false,
    [FIELDS.ERROR]: action.error,
  }),

  [updateDepartment.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_UPDATING]: true,
  }),
  [updateDepartment.fulfilled]: (state, action) => {
    const current = state?.[FIELDS.DEPARTMENTS];
    const id = action.payload?.[COMMON_FIELDS.ID];
    const index = findIndex(current, { [COMMON_FIELDS.ID]: id });
    let result = current;
    if (index !== -1) {
      result = [...(current?.slice(0, index) || []), action.payload, ...(current?.slice(index + 1) || [])];
    }
    return {
      ...state,
      [FIELDS.IS_UPDATING]: false,
      [FIELDS.DEPARTMENT]: action.payload,
      [FIELDS.DEPARTMENTS]: result,
    };
  },
  [updateDepartment.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_UPDATING]: false,
    [FIELDS.ERROR]: action.error,
  }),

  [deleteDepartment.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_DELETING]: true,
  }),
  [deleteDepartment.fulfilled]: (state, action) => {
    const current = state?.[FIELDS.DEPARTMENTS];
    const index = findIndex(current, { [COMMON_FIELDS.ID]: action.payload });
    let result = current;
    if (index !== -1) {
      result = [...(current?.slice(0, index) || []), ...(current?.slice(index + 1) || [])];
    }
    return {
      ...state,
      [FIELDS.IS_DELETING]: false,
      [FIELDS.DEPARTMENTS]: [...result],
    };
  },
  [deleteDepartment.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_DELETING]: false,
    [FIELDS.ERROR]: action.error,
  }),
};

const departmentSlice = createSlice({
  name: REDUCER_STATE.DEPARTMENT.NAME,
  initialState,
  reducers,
  extraReducers,
});

export const { setCurrentDepartment } = departmentSlice.actions;

export { departmentSlice };
