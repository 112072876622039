import React, { useEffect, useState } from 'react';
import { Avatar, Button, Menu, Popconfirm, Popover, Space, Tag, Tooltip } from 'antd';
import uniImage from '@client/static/uni-logo.svg';
import { IdcardOutlined, LogoutOutlined } from '@ant-design/icons';
import { Dashboard, File, ListNumbers } from '@ricons/tabler';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser, logout, updateProfile } from '@client/store/actions/auth';
import { REDUCER_STATE } from '@client/constants/store';
import Loading from '@client/components/loading';
import { generatePath, matchPath, Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import { ADMIN_ROUTES, FORM_ANSWERS_ROUTES, ROOT_ROUTES } from '@client/constants/routes';
import PropTypes from 'prop-types';
import { CenterWrapper, IconWrapper } from '@client/styles/wrapper';
import {
  AuthContentLayout,
  AuthLayout,
  AuthLayoutContent,
  AuthLayoutCornerHeader,
  AuthLayoutHeader,
  AuthLayoutSideFooter,
  AuthLayoutSider,
} from '@client/styles/layout';
import { OSGLogoLight, UniversityLogo } from '@client/components/logo';
import { USER_ENUMS, USER_FIELDS } from '@client/constants/db-fields/user';
import { MESSAGE } from '@client/constants/messages';
import { DEVELOPED_BY, USER_ROLE_TAG_COLOR } from '@client/constants';
import { PERMISSION_TYPES, PERMISSIONS } from '@client/constants/permissions';
import { isEmpty } from 'lodash';
import { COMMON_FIELDS } from '@client/constants/db-fields/shared';
import PathBreadcrumb from '@client/components/reused-component/path-breadcrumb';
import PathTooltip from '@client/components/reused-component/path-tooltip';

const AuthRoute = ({ component: Component, ...otherProps }) => {
  const { path } = otherProps;
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { pathname } = location;
  const isVerifying = useSelector(state => state?.[REDUCER_STATE.AUTH.NAME]?.[REDUCER_STATE.AUTH.FIELDS.IS_VERIFYING]);
  const isLoggedIn = useSelector(state => state?.[REDUCER_STATE.AUTH.NAME]?.[REDUCER_STATE.AUTH.FIELDS.IS_LOGGED_IN]);
  const currentUser = useSelector(state => state?.[REDUCER_STATE.AUTH.NAME]?.[REDUCER_STATE.AUTH.FIELDS.DATA])?.user;
  const version = useSelector(state => state?.[REDUCER_STATE.GENERAL.NAME]?.[REDUCER_STATE.GENERAL.FIELDS.GIT_VERSION]);

  const MENU_ITEM_KEY = {
    DASHBOARD: 'DASHBOARD',
    OFFERINGS: 'OFFERINGS',
    FORM: 'FORM',
    TEMPLATE: 'TEMPLATE',
    ADMIN: 'ADMIN',
  };
  const MENU_ITEM = {
    DASHBOARD: {
      key: MENU_ITEM_KEY.DASHBOARD,
      name: 'Dashboard',
      icon: (
        <IconWrapper>
          <Dashboard />
        </IconWrapper>
      ),
      isVisible: PERMISSIONS?.[currentUser?.[USER_FIELDS.CURRENT_ROLE]]?.[PERMISSION_TYPES.CAN_ACCESS_DASHBOARD],
    },
    ADMIN: {
      key: MENU_ITEM_KEY.ADMIN,
      icon: (
        <IconWrapper>
          <ListNumbers />
        </IconWrapper>
      ),
      name: 'Admin',
      isVisible: PERMISSIONS?.[currentUser?.[USER_FIELDS.CURRENT_ROLE]]?.[PERMISSION_TYPES.CAN_ACCESS_ADMIN],
    },
    FORM: {
      key: MENU_ITEM_KEY.FORM,
      icon: (
        <IconWrapper>
          <File />
        </IconWrapper>
      ),
      name: 'Subject Guides',
      isVisible: true,
    },
  };

  const [selectedKey, setSelectedKey] = useState(MENU_ITEM_KEY.DASHBOARD);

  /**
   * Handle switch role
   * @param role
   */
  const handleSwitchRole = role => {
    dispatch(updateProfile({ userId: currentUser?.[USER_FIELDS.ID], body: { [USER_FIELDS.CURRENT_ROLE]: role } }));
    history.go(0);
  };

  /**
   * Handle logout
   */
  const handleLogout = () => {
    dispatch(logout());
    history.push(ROOT_ROUTES.HOME);
  };

  /**
   * Handle click menu item
   * @param {string} key - menu item key
   */
  const handleClickMenu = key => {
    setSelectedKey(key);
    if (key === MENU_ITEM_KEY.DASHBOARD) {
      history.push(ROOT_ROUTES.HOME);
    } else if (key === MENU_ITEM_KEY.OFFERINGS) {
      history.push(ROOT_ROUTES.OFFERINGS);
    } else if (key === MENU_ITEM_KEY.SUBJECTS) {
      history.push(ADMIN_ROUTES.SUBJECTS);
    } else if (key === MENU_ITEM_KEY.FORM) {
      history.push(ROOT_ROUTES.FORM_ANSWERS);
    } else if (key === MENU_ITEM_KEY.TEMPLATE) {
      history.push(ROOT_ROUTES.TEMPLATE);
    } else if (key === MENU_ITEM_KEY.USERS) {
      history.push(ADMIN_ROUTES.USERS);
    } else if (key === MENU_ITEM_KEY.ADMIN) {
      history.push(ROOT_ROUTES.ADMIN);
    }
  };

  /**
   * Check permission to enter this route
   * @return {boolean}
   */
  useEffect(() => {
    const isEnterCreateNewGuidesRoute = !isEmpty(
      matchPath(pathname, {
        path: FORM_ANSWERS_ROUTES.NEW_FORM,
        exact: true,
        strict: false,
      }),
    );
    const homepage = [USER_ENUMS.ROLES.ADMIN, USER_ENUMS.ROLES.QA].includes(currentUser?.[USER_FIELDS.CURRENT_ROLE])
      ? ROOT_ROUTES.HOME
      : ROOT_ROUTES.FORM_ANSWERS;

    if (!isVerifying && !isEmpty(currentUser)) {
      let isBackToHome = false;
      if (pathname === ROOT_ROUTES.HOME && !PERMISSIONS?.[currentUser?.[USER_FIELDS.CURRENT_ROLE]]?.[PERMISSION_TYPES.CAN_ACCESS_DASHBOARD]) {
        isBackToHome = true;
      }
      if (pathname === ADMIN_ROUTES.USERS && !PERMISSIONS?.[currentUser?.[USER_FIELDS.CURRENT_ROLE]]?.[PERMISSION_TYPES.CAN_MANAGE_USERS]) {
        isBackToHome = true;
      } else if (
        pathname === ADMIN_ROUTES.SUBJECTS &&
        !PERMISSIONS?.[currentUser?.[USER_FIELDS.CURRENT_ROLE]]?.[PERMISSION_TYPES.CAN_MANAGE_SUBJECTS]
      ) {
        isBackToHome = true;
      } else if (pathname === ROOT_ROUTES.ADMIN && !PERMISSIONS?.[currentUser?.[USER_FIELDS.CURRENT_ROLE]]?.[PERMISSION_TYPES.CAN_ACCESS_ADMIN]) {
        isBackToHome = true;
      } else if (isEnterCreateNewGuidesRoute && !PERMISSIONS?.[currentUser?.[USER_FIELDS.CURRENT_ROLE]]?.[PERMISSION_TYPES.CAN_CREATE_NEW_GUIDES]) {
        isBackToHome = true;
      }
      if (isBackToHome) {
        history.push(homepage);
      }
    }
  }, [pathname, currentUser, history, isVerifying]);

  /**
   * Check auth before entering the protected routes
   */
  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch, path]);

  /**
   * Set default selected key according to pathname
   */
  useEffect(() => {
    const isEnterAdminRoute = matchPath(pathname, { path: ROOT_ROUTES.ADMIN, exact: false, strict: false });
    if (pathname === ROOT_ROUTES.HOME) {
      setSelectedKey(MENU_ITEM_KEY.DASHBOARD);
    } else if (pathname === ROOT_ROUTES.OFFERINGS) {
      setSelectedKey(MENU_ITEM_KEY.OFFERINGS);
    } else if (pathname.includes(ROOT_ROUTES.FORM_ANSWERS)) {
      setSelectedKey(MENU_ITEM_KEY.FORM);
    } else if (pathname === ROOT_ROUTES.TEMPLATE) {
      setSelectedKey(MENU_ITEM_KEY.TEMPLATE);
    } else if (isEnterAdminRoute) {
      setSelectedKey(MENU_ITEM_KEY.ADMIN);
    }
  }, [pathname, MENU_ITEM_KEY.DASHBOARD, MENU_ITEM_KEY.FORM, MENU_ITEM_KEY.TEMPLATE, MENU_ITEM_KEY.OFFERINGS, MENU_ITEM_KEY.ADMIN]);

  if (isVerifying) {
    return <Loading fullScreen={false} tip={MESSAGE.LOADING.VERIFYING} />;
  } else {
    if (isLoggedIn) {
      return (
        <AuthLayout>
          <AuthLayoutSider>
            <AuthLayoutCornerHeader>
              <CenterWrapper>
                <div className={'logo'}>
                  <OSGLogoLight />
                </div>
              </CenterWrapper>
            </AuthLayoutCornerHeader>
            <Menu
              mode='inline'
              defaultSelectedKeys={[MENU_ITEM_KEY.DASHBOARD]}
              items={Object.keys(MENU_ITEM)?.map(each => {
                if (MENU_ITEM?.[each]?.isVisible) {
                  return {
                    label: MENU_ITEM?.[each]?.name,
                    key: MENU_ITEM?.[each]?.key,
                    icon: MENU_ITEM?.[each]?.icon,
                  };
                } else {
                  return null;
                }
              })}
              onClick={e => handleClickMenu(e?.key)}
              selectedKeys={[selectedKey]}
            />
            <AuthLayoutSideFooter>
              <CenterWrapper>
                <div className={'logo'}>
                  <UniversityLogo />
                </div>
              </CenterWrapper>
              <div>{DEVELOPED_BY}</div>
              <div>(Version: {version})</div>
            </AuthLayoutSideFooter>
          </AuthLayoutSider>
          <AuthContentLayout>
            <AuthLayoutHeader>
              <Space align={'center'}>
                <PathBreadcrumb />
                <PathTooltip />
              </Space>
              <div className={'tools'}>
                <div className={'user_info_wrapper'}>
                  <Avatar size={32} icon={<img alt='avatar' src={uniImage} />} />
                  <div>{currentUser?.[USER_FIELDS.EMAIL]}</div>
                  <Tooltip
                    placement={'leftBottom'}
                    title={
                      <div style={{ maxHeight: '400px', overflowY: 'scroll' }}>
                        You can
                        <ul>
                          {MESSAGE.TOOLTIP.ROLE_INSTRUCTIONS?.[currentUser?.[USER_FIELDS.CURRENT_ROLE]]?.map((each, index) => (
                            <li key={index}>{each}</li>
                          ))}
                        </ul>
                      </div>
                    }
                  >
                    <Tag color={USER_ROLE_TAG_COLOR?.[currentUser?.[USER_FIELDS.CURRENT_ROLE]]}>{currentUser?.[USER_FIELDS.CURRENT_ROLE]}</Tag>
                  </Tooltip>
                </div>
                {currentUser?.[USER_FIELDS.ASSIGNED_ROLES]?.filter(each => each !== currentUser?.[USER_FIELDS.CURRENT_ROLE])?.length > 0 ? (
                  <Popover
                    placement='bottomLeft'
                    content={currentUser?.[USER_FIELDS.ASSIGNED_ROLES]
                      ?.filter(each => each !== currentUser?.[USER_FIELDS.CURRENT_ROLE])
                      ?.map(each => {
                        return (
                          <Tag key={each} color={USER_ROLE_TAG_COLOR?.[each]} style={{ cursor: 'pointer' }} onClick={() => handleSwitchRole(each)}>
                            {each}
                          </Tag>
                        );
                      })}
                  >
                    <Button type={'default'}>Switch role</Button>
                  </Popover>
                ) : null}
                <IdcardOutlined
                  onClick={() => {
                    const url = generatePath(ROOT_ROUTES.USER, {
                      userId: currentUser?.[COMMON_FIELDS.ID],
                    });
                    history.push(url);
                  }}
                />
                <Popconfirm title={MESSAGE.CONFIRMATION.LOGOUT} onConfirm={handleLogout}>
                  <LogoutOutlined />
                </Popconfirm>
              </div>
            </AuthLayoutHeader>
            <AuthLayoutContent>
              <Route {...otherProps} render={props => <Component {...props} />} />
            </AuthLayoutContent>
          </AuthContentLayout>
        </AuthLayout>
      );
    } else {
      return <Redirect to={{ pathname: ROOT_ROUTES.LOG_IN, state: { redirectedFrom: `${location.pathname}${location.search}` } }} />;
    }
  }
};

AuthRoute.propTypes = {
  component: PropTypes.object.isRequired,
};

export default AuthRoute;
