import BaseApi from './base';

class CommentApi extends BaseApi {
  /**
   * Get comment
   * @return {Promise<*>}
   */
  async getComment(formAnswerId) {
    const _self = this;
    const query = {};
    if (formAnswerId) query.formAnswerId = formAnswerId;
    try {
      const res = await _self.client.get(_self.endpoints.COMMENTS, { params: query });
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Create comment
   * @return {Promise<*>}
   */
  async createComment({ formAnswerId, content, replyTo, questionId = null, pageName = null }) {
    const _self = this;
    try {
      const body = { formAnswerId, content };
      if (replyTo) body.replyTo = replyTo;
      if (questionId) body.questionId = questionId;
      if (pageName) body.pageName = pageName;

      const res = await _self.client.post(_self.endpoints.COMMENTS, body);
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Update comment
   * @return {Promise<*>}
   */
  async updateComment({ id, content, status }) {
    const _self = this;
    try {
      const res = await _self.client.put(_self.endpoints.COMMENT({ commentId: id }), { content, status });
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Delete comment
   * @return {Promise<*>}
   */
  async deleteComment(id) {
    const _self = this;
    try {
      const res = await _self.client.delete(_self.endpoints.COMMENT({ commentId: id }));
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }
}

export { CommentApi };
