import { createSlice } from '@reduxjs/toolkit';
import { REDUCER_STATE } from '@client/constants/store';
import {
  createFormAnswer,
  deleteFormAnswer,
  downloadAnswerAsHtml,
  downloadAnswerAsPdf,
  duplicateFormAnswer,
  getCapsData,
  getForm,
  getFormAnswer,
  getFormAnswers,
  getForms,
  getHistoryFormAnswers,
  getHistoryForms,
  lockFormAnswer,
  unlockFormAnswer,
  updateFormAnswer,
  updateFormAnswerStatus,
} from '@client/store/actions/form';
import { COMMON_FIELDS } from '@client/constants/db-fields/shared';
import { findIndex } from 'lodash';
import { FORM_ANSWER_FIELDS } from '@client/constants/db-fields/formAnswers';

const {
  FORM: { FIELDS },
} = REDUCER_STATE;

const initialState = {
  [FIELDS.ERROR]: null,
  [FIELDS.FORM]: null,
  [FIELDS.FORMS]: [],
  [FIELDS.FORM_ANSWER]: null,
  [FIELDS.FORM_ANSWERS]: [],
  [FIELDS.HISTORY_FORM_ANSWERS]: [],
  [FIELDS.HISTORY_FORMS]: [],
  [FIELDS.CAPS_DATA]: null,
  [FIELDS.PDF_DATA]: null,
  [FIELDS.IS_GETTING_FORM]: false,
  [FIELDS.IS_GETTING_FORM_ANSWER]: false,
  [FIELDS.IS_UPDATING_FORM_ANSWER]: false,
  [FIELDS.IS_CREATING_FORM_ANSWER]: false,
  [FIELDS.IS_GETTING_PREFILLED_DATA]: false,
  [FIELDS.IS_DELETING_FORM_ANSWER]: false,
  [FIELDS.IS_UPDATING_FORM_ANSWER_STATUS]: false,
  [FIELDS.IS_LOCKING]: false,
  [FIELDS.IS_UNLOCKING]: false,
  [FIELDS.IS_DOWNLOADING]: false,
  [FIELDS.IS_DOWNLOADING_HTML]: false,
};

const reducers = {
  clearFormAnswer: state => {
    state[FIELDS.FORM_ANSWER] = null;
  },
};

const extraReducers = {
  [getForm.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_FORM]: true,
    [FIELDS.FORM]: null,
  }),
  [getForm.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_FORM]: false,
    [FIELDS.FORM]: action.payload,
  }),
  [getForm.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_FORM]: false,
    [FIELDS.ERROR]: action.error,
  }),

  [getForms.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_FORM]: true,
    [FIELDS.FORMS]: [],
  }),
  [getForms.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_FORM]: false,
    [FIELDS.FORMS]: action.payload,
  }),
  [getForms.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_FORM]: false,
    [FIELDS.ERROR]: action.error,
  }),

  [getHistoryFormAnswers.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_FORM_ANSWER]: true,
    [FIELDS.HISTORY_FORM_ANSWERS]: [],
  }),
  [getHistoryFormAnswers.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_FORM_ANSWER]: false,
    [FIELDS.HISTORY_FORM_ANSWERS]: action.payload,
  }),
  [getHistoryFormAnswers.rejected]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_FORM_ANSWER]: true,
  }),

  [getHistoryForms.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_FORM]: true,
    [FIELDS.HISTORY_FORMS]: [],
  }),
  [getHistoryForms.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_FORM]: false,
    [FIELDS.HISTORY_FORMS]: action.payload,
  }),
  [getHistoryForms.rejected]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_FORM]: true,
  }),

  [getFormAnswers.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_FORM_ANSWER]: true,
  }),
  [getFormAnswers.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_FORM_ANSWER]: false,
    [FIELDS.FORM_ANSWERS]: action.payload,
  }),
  [getFormAnswers.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_FORM_ANSWER]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [getFormAnswer.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_FORM_ANSWER]: true,
  }),
  [getFormAnswer.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_FORM_ANSWER]: false,
    [FIELDS.FORM_ANSWER]: action.payload,
  }),
  [getFormAnswer.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_FORM_ANSWER]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [createFormAnswer.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_CREATING_FORM_ANSWER]: true,
  }),
  [createFormAnswer.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_CREATING_FORM_ANSWER]: false,
    [FIELDS.FORM_ANSWER]: action.payload,
  }),
  [createFormAnswer.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_CREATING_FORM_ANSWER]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [updateFormAnswer.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_UPDATING_FORM_ANSWER]: true,
  }),
  [updateFormAnswer.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_UPDATING_FORM_ANSWER]: false,
    [FIELDS.FORM_ANSWER]: action.payload,
  }),
  [updateFormAnswer.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_UPDATING_FORM_ANSWER]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [updateFormAnswerStatus.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_UPDATING_FORM_ANSWER_STATUS]: true,
  }),
  [updateFormAnswerStatus.fulfilled]: (state, action) => {
    const current = state[FIELDS.FORM_ANSWERS];
    const index = findIndex(current, { [FORM_ANSWER_FIELDS.ID]: action.payload?.[FORM_ANSWER_FIELDS.ID] });
    let newAnswers = current;
    if (index !== -1) {
      newAnswers = [...(current?.slice(0, index) || []), action.payload, ...(current?.slice(index + 1) || [])];
    }
    if (state[FIELDS.FORM_ANSWER]?.[FORM_ANSWER_FIELDS.ID] === action.payload?.[FORM_ANSWER_FIELDS.ID]) {
      return {
        ...state,
        [FIELDS.IS_UPDATING_FORM_ANSWER_STATUS]: false,
        [FIELDS.FORM_ANSWERS]: newAnswers,
        [FIELDS.FORM_ANSWER]: action.payload,
      };
    } else {
      return {
        ...state,
        [FIELDS.IS_UPDATING_FORM_ANSWER_STATUS]: false,
        [FIELDS.FORM_ANSWERS]: newAnswers,
      };
    }
  },
  [updateFormAnswerStatus.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_UPDATING_FORM_ANSWER_STATUS]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [deleteFormAnswer.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_DELETING_FORM_ANSWER]: true,
  }),
  [deleteFormAnswer.fulfilled]: (state, action) => {
    const existed = state?.[FIELDS.FORM_ANSWERS];
    const deletedId = action.payload;
    const newAnswers = existed?.filter(each => each?.[COMMON_FIELDS.ID] !== deletedId);
    return {
      ...state,
      [FIELDS.ERROR]: null,
      [FIELDS.IS_DELETING_FORM_ANSWER]: false,
      [FIELDS.FORM_ANSWERS]: newAnswers,
    };
  },
  [deleteFormAnswer.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_DELETING_FORM_ANSWER]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [getCapsData.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.CAPS_DATA]: null,
    [FIELDS.IS_GETTING_PREFILLED_DATA]: true,
  }),
  [getCapsData.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_PREFILLED_DATA]: false,
    [FIELDS.CAPS_DATA]: action.payload,
  }),
  [getCapsData.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_PREFILLED_DATA]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [downloadAnswerAsPdf.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.PDF_DATA]: null,
    [FIELDS.IS_DOWNLOADING]: true,
  }),
  [downloadAnswerAsPdf.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.PDF_DATA]: action.payload,
    [FIELDS.IS_DOWNLOADING]: false,
  }),
  [downloadAnswerAsPdf.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_DOWNLOADING]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [downloadAnswerAsHtml.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.HTML_DATA]: null,
    [FIELDS.IS_DOWNLOADING_HTML]: true,
  }),
  [downloadAnswerAsHtml.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.HTML_DATA]: action.payload,
    [FIELDS.IS_DOWNLOADING_HTML]: false,
  }),
  [downloadAnswerAsHtml.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_DOWNLOADING_HTML]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [lockFormAnswer.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_LOCKING]: true,
  }),
  [lockFormAnswer.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.FORM_ANSWER]: action.payload,
    [FIELDS.IS_LOCKING]: false,
  }),
  [lockFormAnswer.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_LOCKING]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [unlockFormAnswer.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_UNLOCKING]: true,
  }),
  [unlockFormAnswer.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.FORM_ANSWER]: action.payload,
    [FIELDS.IS_UNLOCKING]: false,
  }),
  [unlockFormAnswer.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_UNLOCKING]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [duplicateFormAnswer.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_DUPLICATING]: true,
  }),
  [duplicateFormAnswer.fulfilled]: (state, action) => {
    const currentFormAnswers = state?.[FIELDS.FORM_ANSWERS];
    const newAnswers = [action.payload, ...currentFormAnswers];

    return {
      ...state,
      [FIELDS.FORM_ANSWERS]: newAnswers,
      [FIELDS.IS_DUPLICATING]: false,
    };
  },
  [duplicateFormAnswer.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_DUPLICATING]: false,
    [FIELDS.ERROR]: action.error,
  }),
};

const slice = createSlice({
  name: REDUCER_STATE.FORM.NAME,
  initialState,
  reducers,
  extraReducers,
});

export const { clearFormAnswer } = slice.actions;
export default slice;
