import { createSlice } from '@reduxjs/toolkit';
import { findIndex } from 'lodash';
import { REDUCER_STATE } from '@client/constants/store';
import { createSubject, createSubjects, deleteSubject, getSubjects, updateSubject } from '@client/store/actions/subject';
import { COMMON_FIELDS } from '@client/constants/db-fields/shared';

const {
  SUBJECT: { FIELDS },
} = REDUCER_STATE;

const initialState = {
  [FIELDS.ERROR]: null,
  [FIELDS.SUBJECTS]: [],
  [FIELDS.SUBJECT]: null,
  [FIELDS.IS_GETTING_SUBJECT]: false,
  [FIELDS.IS_CREATING]: false,
  [FIELDS.IS_CREATING_SUBJECTS]: false,
  [FIELDS.IS_DELETING]: false,
  [FIELDS.IS_UPDATING]: false,
};

const reducers = {
  clearSubjects: state => {
    state[FIELDS.SUBJECTS] = [];
  },
};

const extraReducers = {
  [getSubjects.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_SUBJECT]: true,
    [FIELDS.SUBJECTS]: [],
  }),
  [getSubjects.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_SUBJECT]: false,
    [FIELDS.SUBJECTS]: action.payload,
  }),
  [getSubjects.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_SUBJECT]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [createSubject.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_CREATING]: true,
  }),
  [createSubject.fulfilled]: (state, action) => {
    const current = state?.[FIELDS.SUBJECTS];
    return {
      ...state,
      [FIELDS.IS_CREATING]: false,
      [FIELDS.SUBJECTS]: [...current, action.payload],
    };
  },
  [createSubject.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_CREATING]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [createSubjects.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_CREATING_SUBJECTS]: true,
  }),
  [createSubjects.fulfilled]: (state, action) => {
    const current = state?.[FIELDS.SUBJECTS];
    return {
      ...state,
      [FIELDS.IS_CREATING_SUBJECTS]: false,
      [FIELDS.SUBJECTS]: [...current, ...action.payload],
    };
  },
  [createSubjects.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_CREATING_SUBJECTS]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [updateSubject.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_UPDATING]: true,
  }),
  [updateSubject.fulfilled]: (state, action) => {
    const current = state?.[FIELDS.SUBJECTS];
    const id = action.payload?.[COMMON_FIELDS.ID];
    const index = findIndex(current, { [COMMON_FIELDS.ID]: id });
    let result = current;
    if (index !== -1) {
      result = [...(current?.slice(0, index) || []), action.payload, ...(current?.slice(index + 1) || [])];
    }
    return {
      ...state,
      [FIELDS.IS_UPDATING]: false,
      [FIELDS.SUBJECTS]: result,
    };
  },
  [updateSubject.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_UPDATING]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [deleteSubject.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_DELETING]: true,
  }),
  [deleteSubject.fulfilled]: (state, action) => {
    const current = state?.[FIELDS.SUBJECTS];
    const index = findIndex(current, { [COMMON_FIELDS.ID]: action.payload });
    let result = current;
    if (index !== -1) {
      result = [...(current?.slice(0, index) || []), ...(current?.slice(index + 1) || [])];
    }
    return {
      ...state,
      [FIELDS.IS_DELETING]: false,
      [FIELDS.SUBJECTS]: [...result],
    };
  },
  [deleteSubject.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_DELETING]: false,
    [FIELDS.ERROR]: action.error,
  }),
};

const subjectSlice = createSlice({
  name: REDUCER_STATE.SUBJECT.NAME,
  initialState,
  reducers,
  extraReducers,
});

export const { clearSubjects } = subjectSlice.actions;

export { subjectSlice };
