import axios from 'axios';
import Qs from 'qs';
import { localforageClient } from '@client/services/localforage';
import { LOCAL_FORAGE_ITEM_KEY } from '@client/constants/localforage';

const baseURL = '/api';
const paramsSerializer = params => Qs.stringify(params, { arrayFormat: 'comma' });

const client = axios.create({
  baseURL,
  paramsSerializer,
});

client.interceptors.request.use(async config => {
  const token = await localforageClient.getItem(LOCAL_FORAGE_ITEM_KEY.SESSION_TOKEN);
  config.headers['Authorization'] = `${token}`;
  return config;
});
export default client;
