import { USER_ENUMS } from '@client/constants/db-fields/user';
import { FORM_ANSWER_ENUMS } from '@client/constants/db-fields/formAnswers';

const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:ss';

const USER_ROLE_TAG_COLOR = {
  [USER_ENUMS.ROLES.ADMIN]: 'red',
  [USER_ENUMS.ROLES.ACADEMIC]: 'geekblue',
  [USER_ENUMS.ROLES.QA]: 'yellow',
};

const GUIDES_STATUS_TAG_COLOR = {
  [FORM_ANSWER_ENUMS.STATUS.SENT_FOR_REVIEWING]: 'geekblue',
  [FORM_ANSWER_ENUMS.STATUS.ACTION_REQUIRED]: 'red',
  [FORM_ANSWER_ENUMS.STATUS.REVIEWED]: 'yellow',
  [FORM_ANSWER_ENUMS.STATUS.PUBLISHED]: 'green',
};

const SEMESTER = {
  SM1: 'SM1',
  SM2: 'SM2',
  WINTER: 'WINTER',
  SUMMER: 'SUMMER',
};

const DEVELOPED_BY = 'Developed by Melbourne eResearch Group 2022-2023';
// const USER_NAME_REGEX = /^[a-zA-Z0-9._-]{3,}$/;
// const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export { DATE_TIME_FORMAT, USER_ROLE_TAG_COLOR, GUIDES_STATUS_TAG_COLOR, DEVELOPED_BY, EMAIL_REGEX, SEMESTER };
