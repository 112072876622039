import React from 'react';
import { useLocation } from 'react-router-dom';
import { ADMIN_ROUTES, ROOT_ROUTES } from '@client/constants/routes';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

const PathTooltip = () => {
  const location = useLocation();
  const path = location?.pathname?.split('')?.join('');
  if (path === ROOT_ROUTES.HOME) {
    return (
      <Tooltip
        placement={'bottomLeft'}
        title={
          <div>
            <p>This page is used to display statistical data</p>
            <p>all data in this page is from the whole website not individual department</p>
          </div>
        }
      >
        <QuestionCircleOutlined />
      </Tooltip>
    );
  } else if (path === ROOT_ROUTES.SUBJECTS) {
    return (
      <Tooltip
        placement={'bottomLeft'}
        title={
          <div>
            <p>This page is used to create subjects</p>
            <p>
              <strong>Create new</strong> is used to create 1 subject
            </p>
            <p>
              <strong>Import</strong> is used to create multiple subjects by using template
            </p>
            <p>
              We need to make sure &quot;subject code&quot; of our created subject is totally match the &quot;subject code&quot; in CAPS In order to
              link subject created with CAPS data
            </p>
          </div>
        }
      >
        <QuestionCircleOutlined />
      </Tooltip>
    );
  } else if (path.includes(ROOT_ROUTES.FORM_ANSWERS)) {
    return (
      <Tooltip
        placement={'bottomLeft'}
        title={
          <div style={{ overflowY: 'scroll', height: '300px' }}>
            <p>This page is used to create the subject guide for each subject</p>
            <p>
              In order to pre-fill all necessary data correctly, please make sure the subject code matches CAPS&apos;s subject code, and there is
              correct coordinator for the subject
            </p>
            <p>
              <strong>Admin user</strong> only be able to view and delete subject guides created by academic users but cannot edit the content of them
            </p>
            <p>
              <strong>QA user</strong> only be able to view subject guides created by academic users within the same department but cannot edit the
              content of them
            </p>
            <p>
              <strong>Academic user</strong> can create and edit subject guides for subject(s) if they are the coordinator of that subject
            </p>
            <div>
              we have 5 statues for each subject guide:
              <ul>
                <li>Draft - the subject guide just been created without filling in all required fields</li>
                <li>Editing - the subject guide with all required fields filled</li>
                <li>
                  Send for reviewing - if one subject guide&apos;s status is &quot;Editing&quot;, the author can click &quot;Send for reviewing&quot;
                  button, to send this guide to QA people for reviewing
                </li>
                <li>Reviewed - QA people can change one subject guide as &quot;reviewed&quot;</li>
                <li>Published - Author of the subject guide can change one subject guide from &quot;reviewed&quot; to &quot;published&quot;</li>
              </ul>
            </div>
          </div>
        }
      >
        <QuestionCircleOutlined />
      </Tooltip>
    );
  } else if (path === ADMIN_ROUTES.USERS) {
    return (
      <Tooltip
        placement={'bottomLeft'}
        title={
          <div>
            <p>This page is used to create users</p>
            <p>
              <strong>Create new</strong> is used to create 1 user
            </p>
            <p>
              <strong>Import</strong> is used to create multiple users by using template
            </p>
            <p>We can set subject(s) for each user, which is used to assign this user as the coordinator of that subject.</p>
          </div>
        }
      >
        <QuestionCircleOutlined />
      </Tooltip>
    );
  } else if (path === ROOT_ROUTES.ADMIN) {
    return (
      <Tooltip
        placement={'bottomLeft'}
        title={
          <div>
            <p>This page is used for admin users</p>
          </div>
        }
      >
        <QuestionCircleOutlined />
      </Tooltip>
    );
  } else {
    return null;
  }
};

export default PathTooltip;
