import BaseApi from './base';

class SubjectApi extends BaseApi {
  /**
   * Get list of subjects
   * @return {Promise<*>}
   */
  async getSubjects(params) {
    const _self = this;
    try {
      const res = await _self.client.get(_self.endpoints.SUBJECTS, { params });
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Create subject
   * @return {Promise<*>}
   */
  async createSubject(payload) {
    const _self = this;
    const params = {
      bulk: false,
    };
    try {
      const res = await _self.client.post(_self.endpoints.SUBJECTS, payload, { params });
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Create subjects
   * @return {Promise<*>}
   */
  async createSubjects(payload) {
    const _self = this;
    const params = {
      bulk: true,
    };
    const body = { subjects: payload };
    try {
      const res = await _self.client.post(_self.endpoints.SUBJECTS, body, { params });
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Update subject
   * @return {Promise<*>}
   */
  async updateSubject({ subjectId, payload }) {
    const _self = this;
    try {
      const res = await _self.client.put(_self.endpoints.SUBJECT({ subjectId }), payload);
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Delete subject
   * @return {Promise<*>}
   */
  async deleteSubject(payload) {
    const _self = this;
    try {
      const res = await _self.client.delete(_self.endpoints.SUBJECT({ subjectId: payload }));
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }
}

export { SubjectApi };
