import { COMMON_FIELDS } from '@client/constants/db-fields/shared';

const OFFERING_ENUMS = {
  SEMESTERS: {
    SEM_1: 'sem1',
    SUMMER: 'summer',
    SEM_2: 'sem2',
    WINTER: 'winter',
  },
};

const OFFERING_FIELDS = {
  ...COMMON_FIELDS,
  CODE: 'code',
  END_DATE: 'endDate',
  NAME: 'name',
  START_DATE: 'startDate',
  TIMETABLE: 'timetable',
};

const OFFERING_LABELS = {
  SEMESTERS: {
    [OFFERING_ENUMS.SEMESTERS.SEM_1]: 'Semester 1',
    [OFFERING_ENUMS.SEMESTERS.SUMMER]: 'Summer Term',
    [OFFERING_ENUMS.SEMESTERS.SEM_2]: 'Semester 2',
    [OFFERING_ENUMS.SEMESTERS.WINTER]: 'Winter Term',
  },
};

export { OFFERING_FIELDS, OFFERING_ENUMS, OFFERING_LABELS };
