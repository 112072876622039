import { COMMON_FIELDS } from '@client/constants/db-fields/shared';

const FORM_ANSWER_ENUMS = {
  STATUS: {
    DRAFT: 'DRAFT',
    SENT_FOR_REVIEWING: 'SENT_FOR_REVIEWING',
    ACTION_REQUIRED: 'ACTION_REQUIRED',
    REVIEWED: 'REVIEWED',
    PUBLISHED: 'PUBLISHED',
  },
};

const FORM_ANSWER_FIELDS = {
  ...COMMON_FIELDS,
  ANSWER: 'answer',
  CREATOR: 'creator',
  FORM: 'form',
  DEPARTMENT: 'department',
  VERSION: 'version',
  OFFERING: 'offering',
  SUBJECT: 'subject',
  LOCKED_BY: 'lockedBy',
  LOCK_EXPIRES_AT: 'lockExpiresAt',
  STATUS: 'status',
  IS_FINISHED_REQUIRED: 'isFinishedRequired',
};
export { FORM_ANSWER_FIELDS, FORM_ANSWER_ENUMS };
