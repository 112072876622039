import { createSlice } from '@reduxjs/toolkit';
import { REDUCER_STATE } from '@client/constants/store';
import { getOfferings, getOfferingYears, createOffering, deleteOffering } from '@client/store/actions/offering';
import { OFFERING_FIELDS } from '@client/constants/db-fields/offering';

const {
  OFFERING: { FIELDS },
} = REDUCER_STATE;

const initialState = {
  [FIELDS.ERROR]: null,
  [FIELDS.AVAILABLE_YEARS]: [],
  [FIELDS.OFFERINGS]: null,
  [FIELDS.IS_GETTING_OFFERING]: false,
  [FIELDS.IS_DELETING]: false,
  [FIELDS.IS_GETTING_YEARS]: false,
  [FIELDS.IS_CREATING]: false,
};

const reducers = {};

const extraReducers = {
  [getOfferings.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_OFFERING]: true,
    [FIELDS.OFFERINGS]: null,
  }),
  [getOfferings.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_OFFERING]: false,
    [FIELDS.OFFERINGS]: action.payload,
  }),
  [getOfferings.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_OFFERING]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [getOfferingYears.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_YEARS]: true,
    [FIELDS.OFFERINGS]: null,
  }),
  [getOfferingYears.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_YEARS]: false,
    [FIELDS.AVAILABLE_YEARS]: action.payload,
  }),
  [getOfferingYears.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_YEARS]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [createOffering.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_CREATING]: true,
  }),
  [createOffering.fulfilled]: (state, action) => {
    const currentOffering = state?.[FIELDS.OFFERINGS];
    return {
      ...state,
      [FIELDS.IS_CREATING]: false,
      [FIELDS.OFFERINGS]: [...currentOffering, action.payload],
    };
  },
  [createOffering.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_CREATING]: false,
    [FIELDS.ERROR]: action.error,
  }),
  [deleteOffering.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_DELETING]: true,
  }),
  [deleteOffering.fulfilled]: (state, action) => {
    const currentOffering = state?.[FIELDS.OFFERINGS];
    return {
      ...state,
      [FIELDS.IS_DELETING]: false,
      [FIELDS.OFFERINGS]: currentOffering?.filter(each => each?.[OFFERING_FIELDS.ID] !== action.payload),
    };
  },
  [deleteOffering.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_DELETING]: false,
    [FIELDS.ERROR]: action.error,
  }),
};

const offeringSlice = createSlice({
  name: REDUCER_STATE.OFFERING.NAME,
  initialState,
  reducers,
  extraReducers,
});

export { offeringSlice };
