import BaseApi from './base';

class OfferingApi extends BaseApi {
  /**
   * Get list of offerings
   * @return {Promise<*>}
   */
  async getOfferings(params = {}) {
    const _self = this;
    const query = {};
    if (params.year) query.year = params.year;
    try {
      const res = await _self.client.get(_self.endpoints.OFFERINGS, { params: query });
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Get available years for offering
   * @return {Promise<*>}
   */
  async getOfferingYears() {
    const _self = this;
    try {
      const res = await _self.client.get(_self.endpoints.OFFERINGS_YEARS);
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Create offering
   * @return {Promise<*>}
   */
  async createOffering(payload) {
    const _self = this;
    try {
      const res = await _self.client.post(_self.endpoints.OFFERINGS, payload);
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Delete offering
   * @return {Promise<*>}
   */
  async deleteOffering(payload) {
    const _self = this;
    try {
      const res = await _self.client.delete(_self.endpoints.OFFERING({ offeringId: payload }));
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }
}

export { OfferingApi };
