import { COMMON_FIELDS } from '@client/constants/db-fields/shared';

const FORM_FIELDS = {
  ...COMMON_FIELDS,
  QUESTION_SET: 'questionSet',
  OPTIONS: 'options',
  CAPS_MAPPERS: 'capsMappers',
};

const FORM_OPTIONS_FIELDS = {
  ID: [COMMON_FIELDS.ID],
  DESCRIPTION: 'description',
  VALUE_TYPE: 'valueType',
  VALUES: 'values',
};
const FORM_QUESTION_SET_FIELDS = {
  ID: [COMMON_FIELDS.ID],
  ABBR: 'abbr',
  DESCRIPTION: 'description',
  LABEL: 'label',
  NAME: 'name',
  QUESTIONS: 'questions',
  TYPE: 'type',
};
const FORM_QUESTION_SET_QUESTIONS_FIELDS = {
  ID: [COMMON_FIELDS.ID],
  ABBR: 'abbr',
  LABEL: 'label',
  NAME: 'name',
  QUESTIONS: 'questions',
  TYPE: 'type',
};
const FORM_QUESTION_SET_QUESTIONS_QUESTION_FIELDS = {
  ID: [COMMON_FIELDS.ID],
  ABBR: 'abbr',
  DESCRIPTION: 'description',
  FORMULA: 'formula',
  INPUT_TYPE: 'inputType',
  LABEL: 'label',
  NAME: 'name',
  OPTIONS: 'options',
  QUESTIONS: 'questions',
  READ_ONLY: 'readOnly',
  SHOW_IF: 'showIf',
  TYPE: 'type',
  VALIDATION: 'validation',
  VARIABLE: 'variable',
};
export {
  FORM_FIELDS,
  FORM_OPTIONS_FIELDS,
  FORM_QUESTION_SET_FIELDS,
  FORM_QUESTION_SET_QUESTIONS_FIELDS,
  FORM_QUESTION_SET_QUESTIONS_QUESTION_FIELDS,
};
