import { createSlice } from '@reduxjs/toolkit';
import { REDUCER_STATE } from '@client/constants/store';
import { getGitVersion, getStatisticalData } from '@client/store/actions/general';

const {
  GENERAL: { FIELDS },
} = REDUCER_STATE;

const initialState = {
  [FIELDS.ERROR]: null,
  [FIELDS.IS_GETTING_GIT_VERSION]: false,
  [FIELDS.IS_GETTING_STATISTICAL_DATA]: false,
  [FIELDS.STATISTICS_DATA]: null,
  [FIELDS.GIT_VERSION]: null,
};
const extraReducers = {
  [getGitVersion.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_GIT_VERSION]: true,
    [FIELDS.GIT_VERSION]: null,
  }),
  [getGitVersion.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_GIT_VERSION]: false,
    [FIELDS.GIT_VERSION]: action.payload,
  }),
  [getGitVersion.rejected]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: action.error,
    [FIELDS.IS_GETTING_GIT_VERSION]: false,
    [FIELDS.GIT_VERSION]: null,
  }),
  [getStatisticalData.pending]: state => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_STATISTICAL_DATA]: true,
    [FIELDS.STATISTICS_DATA]: null,
  }),
  [getStatisticalData.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_STATISTICAL_DATA]: false,
    [FIELDS.STATISTICS_DATA]: action.payload,
  }),
  [getStatisticalData.rejected]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: action.error,
    [FIELDS.IS_GETTING_STATISTICAL_DATA]: false,
    [FIELDS.STATISTICS_DATA]: null,
  }),
};

const generalSlice = createSlice({
  name: REDUCER_STATE.GENERAL.NAME,
  initialState,
  reducers: {},
  extraReducers,
});

export { generalSlice };
