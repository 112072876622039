import BaseApi from '@client/services/api/modules/base';

class GeneralApi extends BaseApi {
  /**
   * Get git version
   * @return {Promise<*>}
   */
  async getGitVersion() {
    const _self = this;
    try {
      const res = await _self.client.get(_self.endpoints.GIT_VERSION);
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }

  /**
   * Get statistics
   * @return {Promise<*>}
   */
  async getStatisticalData() {
    const _self = this;
    try {
      const res = await _self.client.get(_self.endpoints.STATISTICAL_DATA);
      return _self.postProcessor(res);
    } catch (err) {
      _self.errorHandler(err);
    }
  }
}

export { GeneralApi };
