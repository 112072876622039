/**
 * Insert variables into string
 * @param {string} strings
 * @param {Array} keys
 * @return {function(*=): string}
 */
import {
  FORM_FIELDS,
  FORM_QUESTION_SET_FIELDS,
  FORM_QUESTION_SET_QUESTIONS_FIELDS,
  FORM_QUESTION_SET_QUESTIONS_QUESTION_FIELDS,
} from '@client/constants/db-fields/form';
import { find, isEmpty } from 'lodash';

const ttl = (strings, ...keys) => {
  return function (collection) {
    let result = [strings[0]];
    collection = collection || {};
    keys.forEach(function (key, i) {
      result.push(collection[key], strings[i + 1]);
    });
    return result.join('');
  };
};

/**
 * Get max length of characters within array of object
 * @param {Array} array
 * @param {string|null} key - key in object which needs to be calculated, if key is null, it will search all keys
 * @return {number}
 */
const getMaxLengthInArray = ({ array, key = null }) => {
  let result = 0;
  if (!key) {
    array.forEach(each => {
      const currObj = each;
      Object.keys(currObj).forEach(eachKey => {
        if (currObj?.[eachKey]?.length > result) {
          result = currObj?.[eachKey]?.length;
        }
      });
    });
  } else {
    array.forEach(each => {
      const currObj = each;
      if (currObj?.[key]?.length > result) {
        result = currObj?.[key]?.length;
      }
    });
  }
  return result;
};

/**
 * Generate random color
 * @return {string}
 */
const generateRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
};

/**
 * Get question object by using path in question set
 * @param {Object} form
 * @param {Array} path
 * @returns {*|{}}
 */
const getQuestionWithPath = ({ form, path }) => {
  const questionsInFirstLevel = form?.[FORM_QUESTION_SET_FIELDS.QUESTIONS];
  let result = path?.reduce((acc, cur) => {
    // Search question set
    let tempResult = find(acc, { [FORM_QUESTION_SET_FIELDS.NAME]: cur });
    // Search questions under question set
    if (isEmpty(tempResult)) {
      // Search question
      tempResult = find(acc?.[FORM_QUESTION_SET_FIELDS.QUESTIONS], {
        [FORM_QUESTION_SET_QUESTIONS_QUESTION_FIELDS.VARIABLE]: cur,
      });
      // Search question set
      if (isEmpty(tempResult)) {
        tempResult = find(acc?.[FORM_QUESTION_SET_FIELDS.QUESTIONS], {
          [FORM_QUESTION_SET_FIELDS.NAME]: cur,
        });
      }
    }

    return tempResult;
  }, questionsInFirstLevel);

  return result || {};
};

/**
 * Format CAPS data into custom department data format
 * We don't need "label" because we don't want to edit them
 * @param {Object} caps
 * @param {Object} form
 */
const formatCapsData = ({ caps, form }) => {
  return Object.keys(caps)
    ?.map(eachKey => {
      const path = form?.[FORM_FIELDS.CAPS_MAPPERS]?.[eachKey]?.path;
      const value = caps[eachKey];
      return {
        path,
        value,
      };
    })
    ?.filter(each => each.path !== undefined);
};

/**
 * Find object in a list of object
 * @param {Array} listOfObject
 * @param {String} value
 * @param {String} key
 * @param {String} loopField - specific field you want to loop
 * @return {null|*}
 */
const recursiveFindObject = ({ listOfObject, value, key, loopField }) => {
  if (!Array.isArray(listOfObject)) {
    throw new Error('You must passed an array of object');
  }

  for (let i = 0; i < listOfObject.length; i++) {
    const currentObj = listOfObject[i];

    if (currentObj[key] === value) {
      return currentObj;
    } else {
      if (loopField) {
        if (Array.isArray(currentObj[loopField]) && currentObj[loopField].length > 0) {
          const result = recursiveFindObject({ listOfObject: currentObj[loopField], value, key, loopField });
          if (result) {
            return result;
          }
        }
      } else {
        let result;
        return Object.keys(currentObj).forEach(each => {
          if (Array.isArray(currentObj[each]) && currentObj[each]?.length > 0) {
            result = recursiveFindObject({ listOfObject: currentObj[each], value, key, loopField });
          }
          if (result) {
            return result;
          }
        });
      }
    }
  }
};

/**
 * Get question with in
 * @param id
 * @param form
 * @return {*|null}
 */
const getQuestionWithId = ({ id, form }) => {
  if (!id || !form) {
    return null;
  }
  const questions = form?.[FORM_FIELDS.QUESTION_SET]?.[FORM_QUESTION_SET_QUESTIONS_FIELDS.QUESTIONS];
  return recursiveFindObject({ listOfObject: questions, key: '_id', value: id, loopField: FORM_QUESTION_SET_QUESTIONS_FIELDS.QUESTIONS });
};

export { ttl, getMaxLengthInArray, generateRandomColor, getQuestionWithPath, formatCapsData, getQuestionWithId };
