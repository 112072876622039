import React from 'react';
import { ReactComponent as UniLogo } from '@client/static/uni-logo.svg';
import { ReactComponent as OSGLogo } from '@client/static/osg-logo.svg';
import { ReactComponent as OSGLogoLight } from '@client/static/osg-logo-light.svg';

const UniversityLogo = () => {
  return <UniLogo />;
};

const OSGAppLogo = () => {
  return <OSGLogo />;
};

export { UniversityLogo, OSGAppLogo, OSGLogoLight };
