import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET_OFFERINGS, GET_OFFERING_YEARS, CREATE_OFFERING, DELETE_OFFERING } from '@client/store/types/offering';
import { offeringApi } from '@client/services/api';

/**
 * Get offerings
 */
const getOfferings = createAsyncThunk(GET_OFFERINGS, async payload => {
  return await offeringApi.getOfferings(payload);
});

/**
 * Get available years
 */
const getOfferingYears = createAsyncThunk(GET_OFFERING_YEARS, async () => {
  return await offeringApi.getOfferingYears();
});

/**
 * Create offering
 */
const createOffering = createAsyncThunk(CREATE_OFFERING, async payload => {
  return await offeringApi.createOffering(payload);
});

/**
 * Delete offering
 */
const deleteOffering = createAsyncThunk(DELETE_OFFERING, async payload => {
  await offeringApi.deleteOffering(payload);
  return payload;
});

export { getOfferings, getOfferingYears, createOffering, deleteOffering };
