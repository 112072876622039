import { USER_ENUMS } from '@client/constants/db-fields/user';

const PERMISSION_TYPES = {
  CAN_MANAGE_USERS: 'CAN_MANAGE_USERS',
  CAN_MANAGE_OFFERINGS: 'CAN_MANAGE_OFFERINGS',
  CAN_MANAGE_SUBJECTS: 'CAN_MANAGE_SUBJECTS',
  CAN_DOWNLOAD_GUIDES: 'CAN_DOWNLOAD_GUIDES',
  CAN_DELETE_GUIDES: 'CAN_DELETE_GUIDES',
  CAN_ACCESS_DASHBOARD: 'CAN_ACCESS_DASHBOARD',
  CAN_ACCESS_ADMIN: 'CAN_ACCESS_ADMIN',
  CAN_CREATE_NEW_GUIDES: 'CAN_CREATE_NEW_GUIDES',
  CAN_DUPLICATE_SUBJECT_GUIDES: 'CAN_DUPLICATE_SUBJECT_GUIDES',
};

const PERMISSIONS = {
  [USER_ENUMS.ROLES.ADMIN]: {
    [PERMISSION_TYPES.CAN_MANAGE_USERS]: true,
    [PERMISSION_TYPES.CAN_MANAGE_OFFERINGS]: true,
    [PERMISSION_TYPES.CAN_MANAGE_SUBJECTS]: true,
    [PERMISSION_TYPES.CAN_DOWNLOAD_GUIDES]: true,
    [PERMISSION_TYPES.CAN_DELETE_GUIDES]: true,
    [PERMISSION_TYPES.CAN_ACCESS_DASHBOARD]: true,
    [PERMISSION_TYPES.CAN_ACCESS_ADMIN]: true,
    [PERMISSION_TYPES.CAN_CREATE_NEW_GUIDES]: false,
    [PERMISSION_TYPES.CAN_DUPLICATE_SUBJECT_GUIDES]: false,
  },
  [USER_ENUMS.ROLES.QA]: {
    [PERMISSION_TYPES.CAN_MANAGE_USERS]: false,
    [PERMISSION_TYPES.CAN_MANAGE_OFFERINGS]: false,
    [PERMISSION_TYPES.CAN_MANAGE_SUBJECTS]: false,
    [PERMISSION_TYPES.CAN_DOWNLOAD_GUIDES]: true,
    [PERMISSION_TYPES.CAN_DELETE_GUIDES]: false,
    [PERMISSION_TYPES.CAN_ACCESS_DASHBOARD]: true,
    [PERMISSION_TYPES.CAN_ACCESS_ADMIN]: false,
    [PERMISSION_TYPES.CAN_CREATE_NEW_GUIDES]: false,
    [PERMISSION_TYPES.CAN_DUPLICATE_SUBJECT_GUIDES]: false,
  },
  [USER_ENUMS.ROLES.ACADEMIC]: {
    [PERMISSION_TYPES.CAN_MANAGE_USERS]: false,
    [PERMISSION_TYPES.CAN_MANAGE_OFFERINGS]: false,
    [PERMISSION_TYPES.CAN_MANAGE_SUBJECTS]: false,
    [PERMISSION_TYPES.CAN_DOWNLOAD_GUIDES]: false,
    [PERMISSION_TYPES.CAN_DELETE_GUIDES]: false,
    [PERMISSION_TYPES.CAN_ACCESS_DASHBOARD]: false,
    [PERMISSION_TYPES.CAN_ACCESS_ADMIN]: false,
    [PERMISSION_TYPES.CAN_CREATE_NEW_GUIDES]: true,
    [PERMISSION_TYPES.CAN_DUPLICATE_SUBJECT_GUIDES]: false,
  },
};

export { PERMISSIONS, PERMISSION_TYPES };
