import { createAsyncThunk } from '@reduxjs/toolkit';
import { commentApi } from '@client/services/api';
import { CREATE_COMMENTS, DELETE_COMMENT, GET_COMMENTS, UPDATE_COMMENTS } from '@client/store/types/comment';

/**
 * Get comment
 */
const getComment = createAsyncThunk(GET_COMMENTS, async formAnswerId => {
  return await commentApi.getComment(formAnswerId);
});

/**
 * Create comment
 */
const createComment = createAsyncThunk(CREATE_COMMENTS, async ({ formAnswerId, content, replyTo = null, questionId = null, pageName = null }) => {
  const body = { formAnswerId, content };
  if (replyTo) body.replyTo = replyTo;
  if (questionId) body.questionId = questionId;
  if (pageName) body.pageName = pageName;
  return await commentApi.createComment(body);
});

/**
 * Update comment
 */
const updateComment = createAsyncThunk(UPDATE_COMMENTS, async ({ id, content, status }) => {
  return await commentApi.updateComment({ id, content, status });
});

/**
 * Delete comment
 */
const deleteComment = createAsyncThunk(DELETE_COMMENT, async id => {
  await commentApi.deleteComment(id);
  return id;
});

export { getComment, createComment, updateComment, deleteComment };
