import client from '@client/services/api/client';
import endpoints from '@client/services/api/endpoints';
import { errorHandler, extractResponse } from '@client/services/api/utils';

class BaseApi {
  constructor() {
    this.client = client;
    this.endpoints = endpoints;
    this.errorHandler = errorHandler;
    this.postProcessor = extractResponse;
  }
}

export default BaseApi;
